import { faListUl } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"

export const AssigneeDetails = ({ row }: any) => {
    const [toggle, setToggle] = useState(false)

    return <>
        <div className='d-flex justify-content-start align-items-start flex-wrap'>
            <FontAwesomeIcon icon={faListUl} className='me-2 text-dark cursor-pointer mt-1 fw-bold' onClick={() => setToggle((prev) => !prev)} />
            {
                !toggle ? row?.ticket_assigns?.length > 0 ? row?.ticket_assigns[0]?.name : "Not Assigned"
                    : <div className="d-flex flex-column">
                        {
                            row?.ticket_assigns?.length > 0 ? row?.ticket_assigns?.map((ele: any, index: any) => <span className='me-1'>{(index)} - {ele?.name}</span>) : "Not Set"
                        }
                    </div>
            }
        </div>
    </>

}