import React from 'react'

const ActiveInactiveStatus = ({ isActive }: any) => {
    let isColorActive = isActive == 0 ? "badge-light text-danger" : "badge-dark text-success" 
    return (
        <span className={`badge badge-${isColorActive}`}>{isActive == 0 ? "Inactive" : "Active"}</span>
    )
}

export default ActiveInactiveStatus
