import React, { useEffect, useState } from 'react';
import GChatBTN from '../../consts/Chats/ChatButtons/GChatBTN';
import ChipsInfos from '../../consts/Chips/ChipsInfos';
import TagsShow from '../../consts/TagsShow/TagsShow';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faHistory } from '@fortawesome/free-solid-svg-icons';
import UpdateTicketTag from './UpdateTicketTag';
import { DateFormateMoment } from '../../consts/toggleColumns/DateFormateMoment';
import { AssigneeDetails } from '../../consts/AssigneeDetails';
import moment from 'moment';
import { catchCurrentOpenedUrl } from '../../consts/components/Utility';
import PermissionContextProvider from '../admins/adminComponents/PermissionContextProvider';
import { adminMenuActionPermissionKeys } from '../admins/adminConstant';

export const columns = (handleFetch?: any, data?: any) => [

    {
        name: 'TICKET NO',
        selector: (row: any) => row.ticketno,
        sortable: true,
        reorder: true,
        id: 1,
        sortField: 'ticketno',

    },

    {
        name: 'CUSTOMER NAME',

        cell: (row: any) => {
            return (
                <div className='text-left mt-2'>




                    <GChatBTN ticketDataParent={{
                        id: row?.id,
                        customerid: row?.customerid,
                        referenceTicket: "ticketListMain",
                        source_status: "ticketList",
                    }} Title={row?.customer?.first_name + ' ' + row?.customer?.last_name} Toggleid={"kt_drawer_chat2_toggle2"}
                        icons={""}
                        tableData={row}
                        refs=""
                        table="tikcet"
                        trackData={data}
                        keyName={catchCurrentOpenedUrl()}
                    ></GChatBTN>
                    <br />
                    <div className=' d-flex  text-dark fs-8 fw-bold flex-wrap ms-1'>
                        <TagsShow Tag={row?.customer?.private_tag}></TagsShow>
                    </div>
                </div>
            )
        },
        sortable: true,
        reorder: true,
        id: 2,
        minWidth: "200px",
        sortField: 'customer.first_name',
    },
    {
        name: 'STATUS',
        selector: (row: any) => {
            let data: any = <ChipsInfos SelectedString={row?.ticketstatus?.name} classes={row?.ticketstatus?.id == 0 ? "warning" : row?.ticketstatus?.id == 1 ? "danger" : row?.ticketstatus?.id == 2 ? "warning" : row?.ticketstatus?.id == 3 ? "success" : row?.ticketstatus?.id == 4 ? "dark" : row?.ticketstatus?.id == 5 ? "primary" : row?.ticketstatus?.id == 6 ? "primary" : "primary"} />
            return data
        },
        sortable: true,
        reorder: true,
        id: 3,
        minWidth: "120px",
        sortField: 'ticketstatus.id',

    },
    {
        name: 'TAGS',
        selector: (row: any) => <UpdateTicketListTag row={row} />,
        // sortable: true,
        reorder: true,
        id: 4,
        minWidth: "120px",
        // sortField: 'tags',

    },
    {
        name: 'Ticket Visibility',
        selector: (row: any) => {
            return row.is_visible == 1 ? "Public" : "Private"
        },
        reorder: true,
        id: 5,
        minWidth: "200px",
        sortField: 'is_visible',
    },

    {
        name: 'CREATED AT ',
        cell: (row: any) => <DateFormateMoment date={row?.createdAt} from="TICKET_CREATED_AT" />,
        sortable: true,
        reorder: true,
        id: 6,
        minWidth: "200px",
        sortField: 'createdAt',
    },
    {
        name: 'UPDATED AT NEW',
        cell: (row: any) => {
            const dateFromNow = row?.updatedAtFormated;
            return dateFromNow
        },
        // sortable: true,
        reorder: true,
        id: 17,
        minWidth: "150px"
    },

    {
        name: 'CATEGORY',
        selector: (row: any) => row?.ticketcategory?.category_name ? row?.ticketcategory?.category_name : "No Category",
        sortable: true,
        reorder: true,
        id: 8,
        minWidth: "150px",
        sortField: 'ticketcategory.category_name',
    },
    {
        name: 'SUB CATEGORY',
        selector: (row: any) => {
            let render: any = ""
            if (row?.ticketsubcat && row?.ticketsubcat?.masterticketsubcategory?.subcategory_name) {
                {
                    render = row?.ticketsubcat?.masterticketsubcategory?.subcategory_name
                }
                return render
            }
        },

        sortable: true,
        reorder: true,
        id: 9,
        minWidth: "150px",
        sortField: 'ticketsubcat.masterticketsubcategory.subcategory_name',

    },
    {
        name: 'SOURCE',
        selector: (row: any) => row?.ticketsource?.name ? row?.ticketsource?.name : "No Category",
        sortable: true,
        reorder: true,
        id: 10,
        minWidth: "200px",
        sortField: 'ticketsource.name',

    },
    {
        name: 'TICKET OWNER',
        selector: (row: any) => row?.ticketadmin?.first_name ? (row?.ticketadmin?.first_name + ' ' + row?.ticketadmin?.last_name) : "Not Assigned",
        minWidth: "200px",
        sortable: true,
        id: 14,
        sortField: 'ticketadmin.first_name',


    },
    {
        name: 'POC',
        selector: (row: any) => row?.customer?.customerAssignedAgent?.first_name ? (row?.customer?.customerAssignedAgent?.first_name + ' ' + row?.customer?.customerAssignedAgent?.last_name) : "Not Assigned",
        minWidth: "200px",
        sortable: true,
        id: 15,
        sortField: 'customer.customerAssignedAgent.first_name',


    },
    {
        name: 'ASSIGNEE',
        selector: (row: any) => <AssigneeDetails row={row} />,
        minWidth: "200px",
        id: 16,
        // sortField: 'ticket_assigns.first_name',
    },
    {
        name: 'CLEANER',
        selector: (row: any) => row.cleanerData ? row.cleanerData?.first_name + " " + row?.cleanerData?.last_name : "No Cleaner",
        // sortable: true,
        reorder: true,
        id: 11,
        minWidth: "200px",
        // sortField: 'cleanerData.first_name',

    },
    {
        name: 'SUPERVISOR',
        selector: (row: any, data: any) => {
            if (row.cleanerData) {
                let str = row?.cleanerData?.supervisors?.map((ele: any) => {
                    return (
                        ele?.supervisorcleaner?.first_name + " " + ele?.supervisorcleaner?.last_name
                    )
                })
                return str
            }
            else return "No Supervisor"
        },
        // sortable: true,
        reorder: true,
        id: 12,
        minWidth: "200px",
        // sortField: 'cleanerData.first_name',
    },

    {

        name: 'UPDATED AT',
        cell: (row: any) => <DateFormateMoment date={row?.updatedAt} from="TICKET_UPDATED_AT" />,
        sortable: true,
        reorder: true,
        id: 7,
        minWidth: "130x",
        sortField: 'updatedAt',
    },

];





const UpdateTicketListTag = ({ row }: any) => {
    const dispatch = useDispatch()

    const updateTicketSpecificTag = useSelector((store: any) => store.Schedules.modelOpenFromCustomerDashboard) // to update from outside 

    const [updateTagModal, setUpdateTagModal] = useState<any>(false)
    const [rowData, setRowData] = useState<any>(row)

    useEffect(() => {
        setRowData(row)
    }, [row?.tags])

    const handleClose = () => {
        dispatch({
            type: "modelOpenFromCustomerDashboard", payload: {
                toggle: false,
                id: null,
                tag: rowData?.tags,
            }
        })
        setUpdateTagModal(false)
    }


    useEffect(() => {
        setUpdateTagModal(updateTicketSpecificTag?.id == row.id ? true : false)
        setRowData(row)
    }, [updateTicketSpecificTag])


    return (
        <>
            <span className='d-flex'>
                <PermissionContextProvider keyName={adminMenuActionPermissionKeys["new-tickets-update-tag"]}>
                    <FontAwesomeIcon onClick={() => setUpdateTagModal(true)} icon={faEdit} /> &nbsp;
                </PermissionContextProvider>

                {rowData?.tags ? <div className=' d-flex  text-dark fs-8 fw-bold flex-wrap ms-1'>
                    <TagsShow Tag={rowData?.tags}></TagsShow>
                </div> : 'Not Set'}
            </span>

            {updateTagModal ? <UpdateTicketTag showModal={updateTagModal} handleClose={handleClose} row={row} setRowData={setRowData} /> : <></>}

        </>
    )
}
