import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Call_logs from '../Old_JobList/Components/InputBox/Call_logs';
import uuid from 'react-uuid';
import ToolTips from '../../consts/Tooltip/ToolTips';
import SentenceShorter from '../../consts/SentenceShorter';
import GChatBTN from '../../consts/Chats/ChatButtons/GChatBTN';
import { postRequest } from '../../consts/Server/Requests';
import { URL_EndPoints } from '../../consts/Server/URL_EndPoints';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import VisitorStatusForm from './VisitorStatus/VisitorStatusForm';
import DueDateForm from './DueDateUpdate/DueDateForm';
import VisitorSourceForm from './VisitorSource/VisitorSourceForm';
import VisitorSocietyForm from './VisitorSociety/VisitorSocietyForm';
import ChipsInfos from '../../consts/Chips/ChipsInfos';
import Calling from '../../consts/Call/Calling';
import Layout from '../../consts/Call/Layout';
import { DateFormate } from '../../consts/toggleColumns/DateFormate';
import { TempsDateFormate } from '../../consts/toggleColumns/TempsDateFormate';
import IsWhatsAppEnabled from '../../consts/components/IsWhatsAppEnabled';
import { catchCurrentOpenedUrl } from '../../consts/components/Utility';
import TicketStatus from '../../consts/reuseModules/TicketStatus';
import { getFullName } from '../../utils/helper.util';
import PermissionContextProvider from '../admins/adminComponents/PermissionContextProvider';
import { adminMenuActionPermissionKeys } from '../admins/adminConstant';
import AdminDueDateForm from '../admins/adminComponents/AdminDueDateForm';
import ListRowToolTip from '../admins/adminComponents/ListRowToolTip';
import { timeConverterUtil } from '../admins/adminUtils';
const userid = JSON.parse(localStorage.getItem("user") || "0")

export const columns = (selectedButton: any, handleFetchAllCallLogs?: any, handleFetchTransactionsClick?: any, customerData?: any) => [

    {
        id: 1,
        reorder: true,
        name: "Name",
        cell: (row: any) => {
            return (
                <div className='text-left '>
                    <div className='d-flex flex-row  text-hover-primary cursor-pointer '>
                        <PermissionContextProvider keyName={adminMenuActionPermissionKeys["customer-leads-whatsapp-notification-single"]}>
                            <IsWhatsAppEnabled
                                row={row}
                                Ids={row?.id}
                                templateType={1}
                                keyName="customerIds"
                                ApiEndUrl="sendBulkWhatsAppTemplate"
                                to="Customer"
                                IsRequired={false}
                            />
                        </PermissionContextProvider>
                        <Chats row={row} selectedButton={selectedButton} trackData={customerData}></Chats>

                        <PermissionContextProvider keyName={adminMenuActionPermissionKeys["customer-leads-call-connect"]}>
                            <Calling title={""} row_id={row?.id} row={row} type="customerid" From={Layout} isOpen={false} />
                        </PermissionContextProvider>


                    </div>
                </div>
            )
        },
        sortField: 'first_name',
        sortable: true,
        width: "200px"
    },
    {
        id: 2,
        reorder: true,
        name: "Customer id",
        cell: (row: any) => row?.id,
        sortField: 'id',
        sortable: true,
    },
    {
        id: 3,
        reorder: true,
        name: "Refered By",
        cell: (row: any) => row?.customerReferBy?.first_name || 'Not Available',
        sortField: 'customerReferBy.first_name',
        sortable: true,
        width: "200px"
    },
    {
        id: 4,
        reorder: true,
        name: "Created",
        cell: (row: any) => TempsDateFormate(row?.createdAt, true),
        sortField: 'createdAt',
        sortable: true,

    },
    {
        id: 5,
        reorder: true,
        name: "Due Date",
        selector: (row: any) => <div className=''>
            <VisitorDueDateUpdate row={row}></VisitorDueDateUpdate>
        </div>,
        sortField: 'tickets.last_date_resolution',
        sortable: true,
        width: "200px"

    },
    {
        id: 19,
        reorder: true,
        name: "Revive date",
        selector: (row: any) => row?.last_revive_date == 0 ? "Not Set" : moment(row?.last_revive_date).format("DD-MM-YYYY"),
        sortField: 'last_revive_date',
        sortable: true,
        width: "120px"

    },
    {
        id: 6,
        reorder: true,
        name: "Source",
        selector: (row: any) => {
            const sourceUpdate: any = <div className='d-flex flex-row'>
                <VisitorSourceUpdate row={row}></VisitorSourceUpdate>
            </div>
            return sourceUpdate;
        },
        sortField: 'customerSource.name',
        sortable: true,
        minWidth: '150px'
    },
    {
        id: 22,
        reorder: true,
        name: <ListRowToolTip anchorId={22} title="Last Completed Call Time" subTitle="L.C.C.T" />,
        selector: (row: any) => moment(row?.last_completed_call_time).format("DD-MM-YYYY HH:mm:ss"),
        sortField: 'last_completed_call_time',
        sortable: true,
        minWidth: '150px'
    },
    {
        id: 23,
        reorder: true,
        name: <ListRowToolTip anchorId={23} title="Last Completed Call DURATION" subTitle="L.C.C.D" />,
        selector: (row: any) => timeConverterUtil(row?.last_completed_call_duration),
        sortField: 'last_completed_call_duration',
        sortable: true,
        minWidth: '150px'
    },
    {
        id: 7,
        reorder: true,
        name: "Sub Source",
        selector: (row: any) => row?.subsource || 'Not set',
        sortField: 'subsource',
        sortable: true,
        minWidth: '150px'
    },
    {
        id: 8,
        reorder: true,
        name: "Society",
        selector: (row: any) => {
            const societyUpdate: any = <div className='d-flex flex-row'>
                <VisitorSocietyUpdate row={row}></VisitorSocietyUpdate>
            </div>
            return societyUpdate;
        },
        sortField: 'society.name',
        sortable: true
    },
    {
        id: 9,
        reorder: true,
        name: "Status",
        selector: (row: any) => {
            const statusUpdate: any = <div className='d-flex flex-row'>
                <VisitorStatusUpdate row={row}></VisitorStatusUpdate>
            </div>
            return statusUpdate;
        },
        sortField: 'masterOperationReasons.name',
        sortable: true
    },
    {
        id: 26,
        reorder: true,
        name: 'Sub Status',
        cell: (row: any) => row?.customerSubStatus?.name,
        sortField: 'customerSubStatus.name',
        sortable: true,
        minWidth: "150px"
    },
    {
        id: 27,
        reorder: true,
        name: 'Ad Name',
        cell: (row: any) => row?.customerSourceDetail?.adName ?? "Not Set",
        sortField: 'customerSourceDetail.adName',
        sortable: true,
        minWidth: "150px"
    },
    {
        id: 28,
        reorder: true,
        name: 'Ad Set Name',
        cell: (row: any) => row?.customerSourceDetail?.adSetName ?? "Not Set",
        sortField: 'customerSourceDetail.adSetName',
        sortable: true,
        minWidth: "150px"
    },
    {
        id: 29,
        reorder: true,
        name: 'Campaign Name',
        cell: (row: any) => row?.customerSourceDetail?.campaignName ?? "Not Set",
        sortField: 'customerSourceDetail.campaignName',
        sortable: true,
        minWidth: "150px"
    },

    {
        id: 10,
        reorder: true,
        name: "Ticket Status",
        selector: (row: any) => {
            return <TicketStatus ticketStatus={row?.tickets[0]?.ticketstatus?.name} ticketStatusId={row?.tickets[0]?.ticketstatus?.id} />
        },
        sortField: 'tickets.ticketstatus.name',
        sortable: true
    },
    {
        id: 11,
        reorder: true,
        name: "Onboarding Date",
        cell: (row: any) => row?.customer_onboarded_datetime ? <DateFormate date={row?.customer_onboarded_datetime} from="Visitor_Created_boarding_Date" /> : "NA",
        sortField: "customer_onboarded_datetime",
        sortable: true,
    },
    {
        id: 12,
        reorder: true,
        name: "App Version",
        selector: (row: any) => row?.last_updated_app_version ?? "Not downloaded",
        sortField: "last_updated_app_version",
        sortable: true,
        minWidth: "150px"
    },
    {
        name: <CallDetailColumn handleFetchAllCallLogs={handleFetchAllCallLogs} />,
        cell: (row: any) => <CallDtails row={row} />,
        // sortable: true,
        minWidth: "250px",
        id: 13,
        reorder: true,
        hideCols: "customer-leads-fetch-call-logs"
    },

    {
        name: <TransactionsColumn handleFetchTransactionsClick={handleFetchTransactionsClick} />,
        cell: (row: any) => <LastTranactions row={row} />,
        minWidth: "200px",
        id: 14,
        reorder: true,
        hideCols: "customer-leads-fetch-transaction"
    },
    {
        id: 15,
        reorder: true,
        name: "Ticket Owner",
        cell: (row: any) => {
            return (row?.tickets && row?.tickets?.length && row?.tickets[0]?.ticketadmin?.first_name) || 'Not Available'
        },
        sortField: "tickets.ticketadmin.first_name",
        sortable: true,
        minWidth: "150px"
    },
    {
        id: 16,
        reorder: true,
        name: "Ticket Assigns",
        cell: (row: any) => {
            if (row?.tickets?.length === 0 || row?.tickets[0]?.ticketAssigns?.length === 0) {
                return "Not Assigned";
            }
            const data = row?.tickets[0]?.ticketAssigns?.map((assign: any) => <ChipsInfos SelectedString={assign?.userDetail?.first_name || 'Unkown name'} classes="primary" />)
            return data;
        },
        minWidth: "200px"
    },
    {
        id: 17,
        reorder: true,
        name: "Ticket Updated At",
        cell: (row: any) => TempsDateFormate(row?.tickets[0]?.ticket_updated_at, true),
        minWidth: "200px"
    },
    {
        id: 18,
        reorder: true,
        name: "Batch",
        cell: (row: any) => row?.customerBatch?.name ?? 'Not Set',
        sortField: "customerBatch.name",
        minWidth: "200px"
    },
    {
        id: 30,
        reorder: true,
        name: "Onboarded by",
        cell: ({ salesPerson }: { salesPerson: { first_name: string, last_name: string } }) => getFullName(salesPerson),
        sortField: "salesPerson.first_name",
        minWidth: "200px"
    },
];
const Chats = ({ row, selectedButton, trackData }: any) => {
    const [ticketid, setTicketid] = useState<any>(null)
    const [ticketidNo, setticketidNo] = useState<any>(null)
    const [customerid, setcustomerid] = useState<any>(null)
    const [subscriptionid, setsubscriptionid] = useState<any>(null)
    let remainingServices: any = ''
    row?.remainingJobs?.forEach((job: any) => {
        const words = job?.job_name?.split(" ");
        for (let i = 0; i < words.length; i++) {
            remainingServices += (words[i].charAt(0));
        }
        remainingServices += ('-' + job?.count + ', ')
    });

    const handleClickChat = async (result: any) => {
        const response: any = await postRequest(URL_EndPoints(null)?.getProspectTicket, {
            customerid: result?.id,
            user_id: userid,
            selectedButton: selectedButton,
            title: `${row?.vehicleno}, Pause Paid, PauseStartDate: ${row?.ctNotAtHome?.startdate}, PauseEndDate: ${row?.ctNotAtHome?.enddate}, ${row?.ctNotAtHome?.masterreason?.reason}, Remaining Services: ${remainingServices}, Rest Amount: ${!row?.fineOrExtra?.discountprice ? 0 : '+ ' + row?.fineOrExtra?.discountprice}`
        }, null)
        setsubscriptionid(response?.data?.data?.subscription_id)
        setTicketid(response?.data?.data?.id)
        setcustomerid(row?.id)
    }
    useEffect(() => {
        setticketidNo(ticketid)
    }, [ticketid])
    return <>
        <div onClick={() => handleClickChat(row)}>
            <GChatBTN
                ticketDataParent={{
                    id: ticketidNo,
                    customerid: customerid,
                    random: Math.random(),
                    source_status: "visitor",
                    source_due_date: "dueDatePuasePaid",
                    defaultDueDate: row?.tickets?.length > 0 ? (row?.tickets[0].tickethistory?.length > 0 ? row?.tickets[0].tickethistory[0]?.due_date : 'Not Set') : 'Not Set',
                    subscriptionid: row?.id,
                    reasonid: row?.status_id,
                    BTNColor: row?.id,
                    referenceTicket: null
                }}
                trackData={trackData}
                keyName={catchCurrentOpenedUrl()}
                Title={row?.first_name || row?.last_name ? row?.first_name + ' ' + row?.last_name : 'Unkown name'}
                Toggleid={"kt_drawer_chat2_toggle2"}
                classes={"text-hover-primary"}
                icons={""}
                refs="renewal"
            ></GChatBTN>
        </div>
    </>
}
const VisitorStatusUpdate = ({ row }: any) => {
    const dispatch = useDispatch()
    const updatedP2PStatus = useSelector((store: any) => store.Schedules.modalOpenData)
    const StatusUpdatedData = useSelector((store: any) => store.VisitorDataReducer.visitorStatuses)
    const [storeRowP2P, setStoreRowP2p] = useState(row)
    const [updateStatusPop, setupdateStatusPop] = useState(false)
    const [StatusUpdatedDataList, setStatusUpdatedDataList] = useState<any>([])
    const [showRes, setshowRes] = useState<any>({})

    useEffect(() => {
        setStoreRowP2p(row)
    }, [row])

    useEffect(() => {
        setStatusUpdatedDataList(StatusUpdatedData)
    }, [StatusUpdatedData])

    useEffect(() => {
        const updatedData = StatusUpdatedDataList?.filter((ele: any) => {
            return ele?.id === storeRowP2P?.status_id
        })
        setshowRes(updatedData?.length > 0 ? updatedData[0] : {
            name: "No Set"
        })
    }, [StatusUpdatedDataList, storeRowP2P?.status_id])

    const handleClose = () => {
        dispatch({
            type: "MODAL_OPEN", payload: {
                toggle: false,
                id: null,
                reasonsid: storeRowP2P?.status_id,

            }
        })
        setupdateStatusPop(false)
    }

    useEffect(() => {
        setupdateStatusPop(updatedP2PStatus?.id === row.id ? true : false)
        setStoreRowP2p(row)
    }, [updatedP2PStatus])

    return <>
        <span >
            <PermissionContextProvider keyName={adminMenuActionPermissionKeys["customer-leads-update-status"]}>
                <FontAwesomeIcon onClick={() => setupdateStatusPop(true)} icon={faEdit} /> &nbsp;
            </PermissionContextProvider>
            {showRes?.name || 'Not Set'}
        </span>
        <VisitorStatusForm
            FilteredData={{
                show: updateStatusPop,
                handleCloseForm: handleClose,
                selectedId: {
                    status_id: row?.status_id,
                    customerid: row?.id,
                }
            }}
            setStoreRowP2p={setStoreRowP2p}
            row={row}
        ></VisitorStatusForm>
    </>
}

const VisitorSocietyUpdate = ({ row }: any) => {
    const dispatch = useDispatch()
    const StatusUpdatedData = useSelector((store: any) => store.VisitorDataReducer.societies)
    const [storeRowP2P, setStoreRowP2p] = useState(row)
    const [updateStatusPop, setupdateStatusPop] = useState(false)
    const [StatusUpdatedDataList, setStatusUpdatedDataList] = useState<any>([])
    const [showRes, setshowRes] = useState<any>({})

    useEffect(() => {
        setStoreRowP2p(row)
    }, [row])

    useEffect(() => {
        setStatusUpdatedDataList(StatusUpdatedData)
    }, [StatusUpdatedData])

    useEffect(() => {
        const updatedData = StatusUpdatedDataList?.filter((ele: any) => {
            return ele?.id === storeRowP2P?.societyid
        })
        setshowRes(updatedData?.length > 0 ? updatedData[0] : {
            name: "No Set"
        })
    }, [StatusUpdatedDataList, storeRowP2P?.societyid])

    const handleClose = () => {
        dispatch({
            type: "MODAL_OPEN", payload: {
                toggle: false,
                id: null,
            }
        })
        setupdateStatusPop(false)
    }

    return <>
        <span >

            <PermissionContextProvider keyName={adminMenuActionPermissionKeys["customer-leads-update-society"]}>
                <FontAwesomeIcon onClick={() => setupdateStatusPop(true)} icon={faEdit} /> &nbsp;
            </PermissionContextProvider>

            {showRes?.name || 'Not Set'}
        </span>
        <VisitorSocietyForm
            FilteredData={{
                show: updateStatusPop,
                handleCloseForm: handleClose,
                selectedId: {
                    societyid: row?.societyid,
                    customerid: row?.id,
                }
            }}
            setStoreRowP2p={setStoreRowP2p}
            row={row}
        ></VisitorSocietyForm>
    </>
}

const VisitorSourceUpdate = ({ row }: any) => {
    const dispatch = useDispatch()
    const StatusUpdatedData = useSelector((store: any) => store.VisitorDataReducer.visitorSources)
    const [storeRowP2P, setStoreRowP2p] = useState(row)
    const [updateStatusPop, setupdateStatusPop] = useState(false)
    const [StatusUpdatedDataList, setStatusUpdatedDataList] = useState<any>([])
    const [showRes, setshowRes] = useState<any>({})

    useEffect(() => {
        setStoreRowP2p(row)
    }, [row])

    useEffect(() => {
        setStatusUpdatedDataList(StatusUpdatedData)
    }, [StatusUpdatedData])

    useEffect(() => {
        const updatedData = StatusUpdatedDataList?.filter((ele: any) => {
            return ele?.id === storeRowP2P?.source_id
        })
        setshowRes(updatedData?.length > 0 ? updatedData[0] : {
            name: "No Set"
        })
    }, [StatusUpdatedDataList, storeRowP2P?.source_id])

    const handleClose = () => {
        dispatch({
            type: "MODAL_OPEN", payload: {
                toggle: false,
                id: null,
            }
        })
        setupdateStatusPop(false)
    }

    return <>
        <span >

            <PermissionContextProvider keyName={adminMenuActionPermissionKeys["customer-leads-source-update"]}>
                <FontAwesomeIcon onClick={() => setupdateStatusPop(true)} icon={faEdit} /> &nbsp;
            </PermissionContextProvider>
            {showRes?.name || 'Not Set'}

        </span>
        <VisitorSourceForm
            FilteredData={{
                show: updateStatusPop,
                handleCloseForm: handleClose,
                selectedId: {
                    source_id: row?.source_id,
                    customerid: row?.id,
                }
            }}
            setStoreRowP2p={setStoreRowP2p}
            row={row}
        ></VisitorSourceForm>
    </>
}

const VisitorDueDateUpdate = ({ row }: any) => {
    const [showDueDatePopup, setShowDueDatePopup] = useState<any>(false)
    const [storeRow, setStoreRow] = useState<any>(row)
    useEffect(() => {
        setStoreRow(row)
    }, [row])

    const setUpdatedDataDueDate = (payload: any) => {
        row['tickets'] = [payload?.response]
        setStoreRow(row)
    }
    return <>
        <span >
            <PermissionContextProvider keyName={adminMenuActionPermissionKeys["customer-leads-due-date-update"]}>
                <FontAwesomeIcon onClick={() => setShowDueDatePopup(true)} icon={faEdit} /> &nbsp;
            </PermissionContextProvider>
            {storeRow?.tickets?.length > 0 ? <DateFormate date={storeRow?.tickets[0]?.last_date_resolution} from="VisitorCustomer_Due_Date" />
                : 'Not Set'}

        </span>

        {
            showDueDatePopup ? <AdminDueDateForm
                show={showDueDatePopup}
                setShow={setShowDueDatePopup}
                setUpdatedData={setUpdatedDataDueDate}
                prevInitialPayload={{
                    ticket_history_id: storeRow?.tickets?.length > 0 ? (storeRow?.tickets[0].tickethistory?.length > 0 ? storeRow?.tickets[0].tickethistory[0]?.id : 0) : 0,
                    ticket_id: storeRow?.tickets?.length > 0 ? storeRow?.tickets[0]?.id : 0,
                    customerid: storeRow?.id,
                    user_id: userid,
                    prev_due_date: storeRow?.tickets?.length > 0 ? (storeRow?.tickets[0].tickethistory?.length > 0 ? storeRow?.tickets[0]?.last_date_resolution : '') : '',
                    due_date: storeRow?.tickets?.length > 0 ? (storeRow?.tickets[0].tickethistory?.length > 0 ? storeRow?.tickets[0]?.last_date_resolution : '') : '',
                }}
                row={row}
                title="Due Date Update"
                apiUrl="updateDueDateVisitor"
                dueDateKeyName="due_date"
                isDateTime={true}
                isServerDueDate={false}
                serverApiUrl="xxxxx"
            /> : <></>

        }


        {/* {
            <DueDateForm FilteredData={{
                show: showDueDatePopup,
                ticket_history_id: storeRow?.tickets?.length > 0 ? (storeRow?.tickets[0].tickethistory?.length > 0 ? storeRow?.tickets[0].tickethistory[0]?.id : 0) : 0,
                ticket_id: storeRow?.tickets?.length > 0 ? storeRow?.tickets[0]?.id : 0,
                customerid: storeRow?.id,
                user_id: userid,
                prev_due_date: storeRow?.tickets?.length > 0 ? (storeRow?.tickets[0].tickethistory?.length > 0 ? storeRow?.tickets[0]?.last_date_resolution : '') : '',
            }} handleCloseForm={handleCloseForm} setStoreRow={setStoreRow} row={row} />
        } */}
    </>
}

const CallDetailColumn = ({ handleFetchAllCallLogs }: any) => {
    return <>
        Call Details <button className='btn btn-primary btn-sm btn-rounded' onClick={handleFetchAllCallLogs} style={{
            fontSize: '10px',
            padding: '5px 7px',
            marginLeft: '7px'
        }}>Fetch All</button>
    </>
}

const CallDtails = ({ row }: any) => {
    const [storedRow, setStoredRow] = useState<any>(row);

    useEffect(() => {
        setStoredRow(row);
    }, [row]);

    return <>
        <div className='card-title'>
            <div className='symbol-group symbol-hover'>
                {storedRow?.callLogs?.map((ele: any, indexs: any) => {
                    let id = uuid()
                    let StartTime = ele?.FormattedStartTime?.split(" ")
                    let sentence = ele?.FromDetails?.name + ' - ' + ((StartTime[0] + " " + StartTime[1]))
                    if (indexs < 5) {
                        return <div className='symbol symbol-40px symbol-circle' id={id} key={id}  >
                            <span className={`symbol-label bg-light-${ele?.Status === "completed" ? "success" : ele?.Status === "failed" ? "warning" : ele?.Status === "no-answer" ? "secondary" : ele?.Status === "canceled" ? "danger" : "primary"} text-${ele?.Status === "completed" ? "success" : ele?.Status === "failed" ? "warning" : "primary"} 40px   fs-7`} >{StartTime[0]} <SentenceShorter sentence={StartTime[1]} index={null} /></span>
                            <ToolTips toolTipId={id} ToolTipShowData={sentence} place={""} />
                        </div>
                    }
                })}
                {/* _______________________________________________________________________ */}
                <CallLogsDetails customerid={storedRow?.id} customer_name={storedRow?.first_name + ' ' + storedRow?.last_name} lengths={storedRow?.callLogs?.length} row={storedRow} setStoredRow={setStoredRow} ></CallLogsDetails>
            </div>
        </div>
    </>
}

const CallLogsDetails = ({ customerid, customer_name, lengths, row, setStoredRow }: any) => {
    const [show, setshow] = useState(false)
    const [Cusomerid, setCusomerid] = useState(0)
    const handleFetchCallDetails = async (customerid: number) => {
        if (lengths === undefined) {
            const response: any = await postRequest(URL_EndPoints()?.getCallHistoryByCustomerIds, {
                customerIds: [row?.id]
            }, null);
            const callLogs: any = response?.data?.data[0];
            setStoredRow({ ...row, callLogs });
            setCusomerid(customerid)
        } else {
            setshow(true)
            setCusomerid(customerid)
        }
    }
    const handleClose = () => {
        setshow(false)
    }
    return (
        <>
            <a
                href='#'
                className='symbol symbol-40px symbol-circle'
                id={customerid}
                onClick={(e: any) => {
                    e.preventDefault();
                    handleFetchCallDetails(customerid);
                }}
            >
                <span
                    className='symbol-label fs-8 fw-bolder bg-primary text-white'
                >
                    {lengths === undefined ? 'Fetch' : lengths}
                </span>
                <ToolTips toolTipId={customerid} ToolTipShowData={lengths === undefined ? "View call logs" : "View All Call Details"} place={""} />
            </a>
            <Call_logs FilteredData={{
                call_log_cusomerid: Cusomerid,
                show: show,
                handleClose,
                customer_name
            }} >
            </Call_logs>
        </>
    )
}

const TransactionsColumn = ({ handleFetchTransactionsClick }: any) => {
    return <>
        Transactions <button className='btn btn-primary btn-sm btn-rounded' onClick={handleFetchTransactionsClick} style={{
            fontSize: '10px',
            padding: '5px 7px',
            marginLeft: '7px'
        }}>Fetch All</button>
    </>
}

const LastTranactions = ({ row }: any) => {

    const [storedRow, setStoredRow] = useState<any>(row);

    useEffect(() => {
        setStoredRow(row);
    }, [row])


    const handleFetchTransactions = async (customerid: number) => {
        const response: any = await postRequest(URL_EndPoints()?.getTransactions, {
            customerid: row?.id,
            doNotInclude: true
        }, null);
        const transactions = response?.data?.data;
        setStoredRow({ ...row, transactions });
    }

    return <div className='card-title bg-'>
        <div className='symbol-group symbol-hover'>
            {storedRow?.transactions?.length === 0 && <div className='symbol symbol-40px symbol-circle' id="notransactions"  >
                <span className={`symbol-label bg-light-secondary text-primary  40px   fs-7`} ><SentenceShorter sentence="No Transaction" index={null} /></span>
                <ToolTips toolTipId="notransactions" ToolTipShowData="No Transaction" place={""} />
            </div>}
            {storedRow?.transactions?.length > 0 ? storedRow?.transactions?.map((ele: any, indexs: any) => {
                let id = uuid()
                let StartTimes = moment(new Date(ele?.createdAt)).fromNow()
                let StartTime = StartTimes?.split(" ")
                let sentence = ele?.cancellation_reason ? ele?.cancellation_reason + ' - ' + ((StartTime[0] + " " + StartTime[1] + " " + StartTime[2])) : "NA" + ' - ' + ((StartTime[0] + " " + StartTime[1] + " " + StartTime[2]))
                if (+storedRow.id != +ele?.subscription?.customerid) {
                    sentence += ` Paid from different account: ${ele?.subscription?.phone}`
                } else {
                    // sentence += "this"
                }
                return <div className='symbol symbol-40px symbol-circle' id={id}  >
                    <span className={`symbol-label bg-light-${ele?.authorization_status === "success" ? "success" : "danger"} text-${ele?.authorization_status === "success" ? "success" : "danger"}  40px   fs-7`} >{StartTime[0]} <SentenceShorter sentence={StartTime[1]} index={null} /></span>
                    <ToolTips toolTipId={id} ToolTipShowData={sentence} place={""} />
                </div>
            })
                :
                <a
                    href='#'
                    className='symbol symbol-40px symbol-circle'
                    onClick={(e: any) => {
                        e.preventDefault();
                        handleFetchTransactions(storedRow?.id);
                    }}
                >
                    <span
                        className='symbol-label fs-8 fw-bolder bg-primary text-white'
                        id='notransaction'
                    >
                        Fetch
                    </span>
                    <ToolTips toolTipId="notransaction" ToolTipShowData="Fetch transactions" place={""} />
                </a>
            }
            {/* _______________________________________________________________________ */}
        </div>
    </div>
}