import React, { useState, useEffect, useRef } from 'react';
import DataTable from 'react-data-table-component'
import { columns } from './Columns';
import { getCurrentFilterHandler, initialPayload, operators, removeFilterHandler } from '../../../DynamicFilteration/components/CommonData';
import { URL_EndPoints } from '../../../Server/URL_EndPoints';
import { postRequest } from '../../../Server/Requests';
import { paginationRowsPerPageOptions, paginationRowsPerPageOptionsAll, reorderColumns } from '../../../components/Utility';
import { DynamicFilter, FilterHandler } from '../../../DynamicFilteration/FilterHandler';
import { KeywiseComponents } from '../../../DynamicFilteration/KeywiseComponents';
import { useAdminListApiUrl, useAdminSmartFilterList } from '../../../../modules/admins/adminUtils';

const ChampDeviceHashList = () => {

  const [pending, setPending] = React.useState(false)
  const [filterData, setfilterData] = useState<any>([])
  const [storeFilterList, setStoreFilterList] = useState<any>({})
  const [selectedFilterData, setSelectedFilterData] = useState<any>([])
  const [totalRows, setTotalRows] = useState<any>(0);
  const [perPageSize, setPerPageSize] = useState(10);
  const [sortby, setSortBy] = useState<any>("");
  const [direction, setdirections] = useState<any>("desc");
  const [page, setPages] = useState<any>(1);
  const [search, setSearch] = useState<any>("");
  const [filterkey, setFilterKey] = useState<any>({})
  const [filter, setFilter] = useState<any>([])
  const [payload, setPayload] = useState<any>(initialPayload(false, {}))
  async function InvokedRendered(payloads: any, setloading: any) {
    setPending(true)
    const { data } = await postRequest(URL_EndPoints(null)?.getUniqueUserHashList, payload, setloading)
    setfilterData(data?.data)
    setTotalRows(data?.recordsTotal)
    setFilter(data?.filter?.length > 0 ? data?.filter  : [])
    setPending(false)
  }

  const handlePagination = (search: any = "", sortBy = "", sortDirection: any = "desc", page: any = 1, perPageSize: any = 10) => {
    payload.search = search
    payload.start = page
    payload.length = perPageSize
    payload.sortby = sortBy
    payload.orders = sortDirection
    payload.direction = sortDirection
    setPayload(payload)
    return payload
  }

  const onChangeHandler = (event: any, key: any, value: any, defVal: any = false, timestamp: any, multiVal: any) => {
    setPayload({ ...payload, filterData: { ...payload.filterData, [key]: { ...payload.filterData[key], [value]:  event?.value, [key + "_" + value]: defVal ? event : "", isDate: defVal ? false : true, timestamp: timestamp === "null" ? undefined : timestamp, multiVal: multiVal } } })
  }

  const actionHandler = async () => {
    await InvokedRendered(handlePagination(search, sortby, direction, page, perPageSize), setPending)
  }

  const removeFilter = (index: any, key: any) => {
    removeFilterHandler(index, key, setPayload, payload, setSelectedFilterData, selectedFilterData)
  }

  const getCurrentFilter = async (key: any, currentItemRemovedOrAdded: any) => {
    getCurrentFilterHandler(key, currentItemRemovedOrAdded, setFilterKey, setSelectedFilterData, selectedFilterData, setStoreFilterList, storeFilterList, payload, setPayload)
  }

  const handleSort = async (column: any, sortDirection: any) => {
    setSortBy(column.sortField)
    setdirections(sortDirection)

    InvokedRendered(handlePagination("", column.sortField, sortDirection, page, perPageSize), setPending)
  };

  const handlePageChange = (page: any) => {
    setPages(page)
    InvokedRendered(handlePagination("", sortby, direction, page, perPageSize), setPending)
  };

  const handlePerRowsChange = async (newPerPageSize: any, page: any) => {
    setPerPageSize(newPerPageSize)
  };

  useEffect(() => {

    let timer = setTimeout(async function () {
      search && setPending(true)
      search && InvokedRendered(handlePagination(search && search, sortby, direction, 1, perPageSize), setPending)
    }, 1000)

    return () => {
      clearTimeout(timer)
    }

  }, [search])

  useEffect(() => {
    !search && InvokedRendered(handlePagination("", sortby, direction, 1, perPageSize), setPending)
  }, [search])

  useEffect(() => {
  }, [selectedFilterData?.length]);

  const isFirstRender = useRef(true);
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    selectedFilterData?.length === 0 && InvokedRendered(handlePagination(search, sortby, direction, 1, perPageSize), setPending);

  }, [selectedFilterData?.length]);

  let { adminGetSmartFilterList } = useAdminSmartFilterList()
  let {listApiUrl  } = useAdminListApiUrl()

  useEffect(() => {
    adminGetSmartFilterList("getUniqueUserHashList")
  }, [])


  return (
    <>
      <div>

        <DataTable
          title=" Champ Device Approval Lists"
          selectableRows 
          progressPending={pending}
          columns={reorderColumns(columns(), "champ_device_login")}
          data={filterData}  
          fixedHeader
          fixedHeaderScrollHeight='auto'  
          selectableRowsHighlight 
          highlightOnHover 
          subHeader 
          pagination  
          sortServer
          onColumnOrderChange={cols => console.log(cols)}
          paginationServer
          onSort={handleSort}
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          paginationComponentOptions={paginationRowsPerPageOptionsAll}
          paginationRowsPerPageOptions={paginationRowsPerPageOptions}
          // server 
          subHeaderComponent={
            <>
              <div className='w-100 d-flex justify-content-between mb-3 '>
                <div className='d-flex align-items-start justify-content-start '>

                  <input
                    type="text"
                    placeholder='Search Here'
                    value={search}
                    onChange={(e: any) => setSearch(e.target.value)}
                    className='w-100 form-control align-start'
                  />
                </div>
                <div className='d-flex align-items-center justify-content-end '>
                  <div className='d-flex align-items-center justify-content-center my-2'>
                    <FilterHandler
                      onChangeHandler={onChangeHandler} 
                      actionHandler={actionHandler} 
                      getCurrentFilter={getCurrentFilter}
                      removeFilter={removeFilter}
                      payload={payload}
                      Component={DynamicFilter}
                      filterData={filter}
                      OperatorsData={operators}
                      selectedFilterData={selectedFilterData}
                      filterComponents={KeywiseComponents()}
                      storeFilterList={storeFilterList}
                      filterkey={filterkey}
                      listApiUrl={listApiUrl("getUniqueUserHashList")}
                    />
                  </div>
                </div>
              </div>
            </>
          }
        />
      </div>
    </>
  )
}


export default ChampDeviceHashList
