import { faListUl } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

export const AssignedAdminDetails = ({ row }: any) => {
    const [toggle, setToggle] = useState(false);

    const getAgentName = (agent: any) => 
        agent ? `${agent.first_name} ${agent.last_name}` : "Not Assigned";

    return (
        <div className='d-flex justify-content-start align-items-start flex-wrap'>
            <FontAwesomeIcon 
                icon={faListUl} 
                className='me-2 text-dark cursor-pointer mt-1 fw-bold' 
                onClick={() => setToggle((prev) => !prev)} 
            />
            {
                !toggle ? (
                    row?.tickets?.[0]?.ticketAssigns?.[0]?.ticketAssignedAgent
                        ? getAgentName(row.tickets[0].ticketAssigns[0].ticketAssignedAgent)
                        : "Not Assigned"
                ) : (
                    <div className="d-flex flex-column">
                        {
                            row?.tickets?.[0]?.ticketAssigns?.length > 0 
                                ? row.tickets[0].ticketAssigns.map((ele: any, index: any) => (
                                    <span key={index} className='me-1'>
                                        {index} - {getAgentName(ele.ticketAssignedAgent)}
                                    </span>
                                  ))
                                : "Not Set"
                        }
                    </div>
                )
            }
        </div>
    );
};