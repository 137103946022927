import React from 'react'
import Select from 'react-select'
import { colourStyles2 } from '../../../../consts/reuseModules/ReactTableComponent/FormStyleCssSheet'
const AdminMultiSelectFilter = (props: any) => {
  const {
    onChangeHandler,
    HeaderTitle,
    SelectData,
    displayFields,
    id,
    name,
    defaultData,
    searchable,
    setSeacrch,
    isLoading = false,
    filters
  } = props

  const [showData, setShowData] = React.useState<any>([])
  React.useEffect(() => {
    const updatedData = SelectData?.map((ele: any, i: number) => {
      const newData = {
        label: displayFields?.map((key: any) => !ele[key] ? "NA" : ele[key]).join(" "),
        value: ele[id],
        item: ele
      }
      return newData
    })
    setShowData(updatedData)
  }, [SelectData])

  const multiOnchange = (event: any) => {
    onChangeHandler({
      item: event,
      value: event?.map((ele: any) => ele?.value),
    }, name, false)
  }

  const debouncing = (event: any) => {
    setSeacrch(event, filters)
  }

  return (
        <Select
          placeholder={HeaderTitle}
          isClearable
          isSearchable
          isMulti
          onInputChange={setSeacrch && searchable ? (e: any) => debouncing(e) : undefined}
          options={showData}
          onChange={(event) => multiOnchange(event)}
          defaultValue={defaultData && defaultData?.item}
          styles={colourStyles2}
          isLoading={isLoading}
        />
  )
}
export default AdminMultiSelectFilter



// <CurrentComponent
// // 👉 for React Select Input Box
// onChangeHandler={onChangeHandler}
// HeaderTitle={element?.HeaderTitle}
// payload={payload}
// SelectData={storeFilterList}
// displayFields={element?.displayFields}
// id={element?.valueField}
// name={element?.name}
// defaultData={null}
// searchable={searchable}
// setSeacrch={setSearchFilter}
// isLoading={dynFilter?.filterApi == element?.filterApi ? isLoadingFilter : false}
// filters={element}
// // 👉 for React Select Input Box
// />