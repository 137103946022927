import React, { useState, useEffect, useRef } from 'react';
import DataTable from 'react-data-table-component'
import { columns } from './Columns';
import { getRequest, postRequest } from '../../consts/Server/Requests';
import { URL_EndPoints } from '../../consts/Server/URL_EndPoints';
import { DynamicFilter, FilterHandler } from '../../consts/DynamicFilteration/FilterHandler';
import { getCurrentFilterHandler, getFilterDataBySearch, initialPayload, operators, removeFilterHandler } from '../../consts/DynamicFilteration/components/CommonData';
import { KeywiseComponents } from '../../consts/DynamicFilteration/KeywiseComponents';
import { toast } from 'react-toastify';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import MultiSelectSearchDetailsDefault from '../../consts/Select/MultiSelectSearchDetailsDefault';
import { useSelector } from 'react-redux';
import { paginationRowsPerPageOptions, paginationRowsPerPageOptionsAll, reorderColumns, storeReorderIndexes, toastNotifier } from '../../consts/components/Utility';
import SingleSelectSearchCategoryDefault from '../../consts/Select/SingleSelectSearchCategoryDefault';
import { ShowHideColumns } from '../../consts/ShowHideColumns';
import { ToggleColumns } from '../../consts/toggleColumns/ToggleColumns';
import MainContainerWrapper from '../../consts/mediaQueryLayout/MainContainerWrapper';
import LeftBox from '../../consts/mediaQueryLayout/LeftBox';
import SearchBoxModal from '../../consts/reuseModules/SearchBoxModal';
import RightBox from '../../consts/mediaQueryLayout/RightBox';
import Refresh from '../../consts/Refresh';
import { BulkUtilButton, BulkUtilButtonWrapper } from '../../consts/reuseModules/BulkUtilButton';
import AdminBulkAddContactsToCampaignList from '../admins/adminForms/AdminBulkAddContactsToCampaignList';
import AdminBulkAssignUnassignForm from '../admins/adminForms/AdminBulkAssignUnassignForm';
import { filteredSelectedRowsIds, useAdminListApiUrl, useAdminSmartFilterList } from '../admins/adminUtils';
import AdminBulkForm from '../admins/adminForms/AdminBulkForm';
import { adminAdminSuperFormData, adminBatcheFormData, adminWatiNotifyTemplatFormData } from '../admins/adminConstant';

const userid = JSON.parse(localStorage.getItem("user") || "0");
const VehicleTypeListMain = ({ filterDataOutsource }: any) => {

  const [whatsappTemplates, setWhatsappTemplates] = useState([]);
  const [batches, setBatches] = useState([]);
  const [pending, setPending] = React.useState(false)
  const [filterData, setfilterData] = useState<any>([])
  const [storeFilterList, setStoreFilterList] = useState<any>({})
  const [selectedFilterData, setSelectedFilterData] = useState<any>([])
  const [filterDataTemp, setfilterDataTemp] = useState<any>([])
  const [totalRows, setTotalRows] = useState<any>(0);
  const [perPageSize, setPerPageSize] = useState(10);
  const [sortby, setSortBy] = useState<any>("");
  const [direction, setdirections] = useState<any>("desc");
  const [page, setPages] = useState<any>(1);
  const [search, setSearch] = useState<any>("");
  const [filterkey, setFilterKey] = useState<any>({})
  const [filter, setFilter] = useState<any>([])
  const [payload, setPayload] = useState<any>(initialPayload(false, {}))
  const [sortedColumns, setSortedColumns] = useState<any>(columns(handleFetchAllCallLogs));

  useEffect(() => {
    if (filterData) {
      const newColumns = columns(handleFetchAllCallLogs, filterData?.map((ele: any) => ele?.vehicleCustomer?.id), refreshNow);
      setSortedColumns(newColumns);
    }
  }, [filterData]);

  async function loadData() {
    const whatsappTemplates: any = await postRequest(URL_EndPoints(null)?.get_WhatsAppTemplate, {
      wa_number_id: 1
    }, null);
    const batches: any = await getRequest(URL_EndPoints(null)?.getBatches, null);

    setWhatsappTemplates(whatsappTemplates?.data?.data);
    setBatches(batches?.data?.data);

  }

  useEffect(() => {
    loadData();
  }, []);

  async function InvokedRendered(payloads: any, setloading: any) {
    setPending(true)
    const { data } = await postRequest(URL_EndPoints(null)?.customerVehicleListPagination, payloads, setloading)
    setfilterData(data?.data)
    setTotalRows(data?.recordsTotal)
    setFilter(data?.filter?.customerVehilcle)
    setPending(false)
  }

  const handlePagination = (search: any = "", sortBy = "", sortDirection: any = "desc", page: any = 1, perPageSize: any = 10) => {
    payload.search = search
    payload.start = page
    payload.length = perPageSize
    payload.sortby = sortBy
    payload.orders = sortDirection
    payload.direction = sortDirection
    setPayload(payload)
    return payload
  }

  const onChangeHandler = (event: any, key: any, value: any, defVal: any = false, timestamp: any, multiVal: any) => {
    setPayload({ ...payload, filterData: { ...payload.filterData, [key]: { ...payload.filterData[key], [value]: event?.value, [key + "_" + value]: defVal ? event : "", isDate: defVal ? false : true, timestamp: timestamp === "null" ? undefined : timestamp, multiVal: multiVal } } })
  }

  const actionHandler = async () => {

    // setTotalRows(10)
    await InvokedRendered(handlePagination(search, sortby, direction, page, perPageSize), setPending)

  }

  const removeFilter = (index: any, key: any) => {
    removeFilterHandler(index, key, setPayload, payload, setSelectedFilterData, selectedFilterData)
  }

  const getCurrentFilter = async (key: any, currentItemRemovedOrAdded: any) => {
    getCurrentFilterHandler(key, currentItemRemovedOrAdded, setFilterKey, setSelectedFilterData, selectedFilterData, setStoreFilterList, storeFilterList, payload, setPayload)
  }

  const handleSort = async (column: any, sortDirection: any) => {
    setSortBy(column.sortField)
    setdirections(sortDirection)

    InvokedRendered(handlePagination(search, column.sortField, sortDirection, page, perPageSize), setPending)
  };

  const handlePageChange = (page: any) => {
    setPages(page)
    InvokedRendered(handlePagination(search, sortby, direction, page, perPageSize), setPending)
  };

  const handlePerRowsChange = async (newPerPageSize: any, page: any) => {
    InvokedRendered(handlePagination(search, sortby, direction, page, newPerPageSize), setPending)
    setPerPageSize(newPerPageSize)
  };

  useEffect(() => {

    let timer = setTimeout(async function () {
      search && setPending(true)
      search && InvokedRendered(handlePagination(search && search, sortby, direction, page, perPageSize), setPending)
    }, 1000)
    return () => {
      clearTimeout(timer)
    }

  }, [search])

  // useEffect(() => {
  //   !search && InvokedRendered(handlePagination(search, sortby, direction, page, perPageSize), setPending)
  // }, [search])

  const isFirstRender = useRef(true);
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    selectedFilterData?.length === 0 && InvokedRendered(handlePagination(search, sortby, direction, page, perPageSize), setPending);

  }, [selectedFilterData?.length]);

  function handleFetchAllCallLogs() {
    setfilterData((prev: any) => {
      setfilterDataTemp(prev);
      return prev;
    });
  }


  useEffect(() => {
    const fetchAllCallHistory = async () => {
      setPending(true);
      const clone = filterDataTemp.slice();
      const customerIds = clone.map((e: any) => e.customer_id);
      const response: any = await postRequest(URL_EndPoints()?.getCallHistoryByCustomerIds, {
        customerIds
      }, setPending);
      const callLogs = response?.data?.data;
      for (let i = 0; i < clone.length; i++) {
        const e = clone[i];
        const foundLog = callLogs?.find((ele: any) => ele[0]?.From === e?.vehicleCustomer?.phone || ele[0]?.To === e?.vehicleCustomer?.phone) || [];
        clone[i].callLogs = foundLog;
      }
      setfilterData(clone);
      setPending(false);
    }
    filterDataTemp?.length > 0 && fetchAllCallHistory();
  }, [filterDataTemp])


  const [filteredSelectedRows, setFilteredSelectedRows] = useState<any>([])
  const [showAssignPopUp, setShowAssignPopUp] = useState(false)
  const [isBulkAssignApiLoading, setIsBulkAssignApiLoading] = useState<any>(false)
  const [assignPayload, setAssignPayload] = useState<any>({
    admins: [],
    cleaners: [],
    should_unassign: false,
    admins_to_unassign: [],
    cleaners_to_unassign: [],
  });

  const handleSelectedRowsChange = (selectedRows: any) => {
    setFilteredSelectedRows(selectedRows?.selectedRows)
  };

  const handleAssignPopUpClose = () => {
    setAssignPayload({
      admins: [],
      cleaners: [],
      should_unassign: false,
      admins_to_unassign: [],
      cleaners_to_unassign: [],
      dueDate: '',
    })
    setShowAssignPopUp(false)
    setIsBulkAssignApiLoading(false)
  }

  const handleAssignSubmit = async (e: any) => {
    e.preventDefault()
    let ids = filteredSelectedRows?.map((row: any) => row?.vehicleInactiveTicket?.id);
    ids = ids.filter((id: number) => (id !== undefined) && (id !== 0));
    assignPayload['ids'] = ids;
    setIsBulkAssignApiLoading(true)
    const response: any = await postRequest(URL_EndPoints(null)?.assignToBulkTickets, assignPayload, null)
    toast?.[response?.data?.status == 200 ? "success" : "error"](response?.data?.msg, { position: "top-center" })
    setFilteredSelectedRows && setFilteredSelectedRows([])
    setToggleClearRows(!toggledClearRows)
    setShowAssignPopUp(false)
    handleAssignPopUpClose();
  }
  const [showNotifyPopUp, setShowNotifyPopUp] = useState(false);
  const [isBulkNotifyLoading, setIsBulkNotifyLoading] = useState(false);
  const [whatsappNotifyPayload, setWhatsappNotifyPayload] = useState<any>({
    templateName: '',
  });
  const handleNotifyPopUpClose = () => {
    setWhatsappNotifyPayload({
      templateName: '',
    });
    setShowNotifyPopUp(false);
  }
  const handleWhatsappInputChange = (e: any) => {
    setWhatsappNotifyPayload({
      ...whatsappNotifyPayload,
      [e.target.name]: e.target.value
    });
  }
  const handleBulkWhatsappNotifySubmit = async (e: any) => {
    e.preventDefault();
    let customerIds = filteredSelectedRows?.map((row: any) => row?.vehicleCustomer?.id);
    whatsappNotifyPayload['customerIds'] = customerIds || [];
    setIsBulkNotifyLoading(true)
    const response: any = await postRequest(URL_EndPoints(null)?.sendBulkWhatsAppTemplate, whatsappNotifyPayload, setIsBulkNotifyLoading)
    toast?.[response?.data?.status == 200 ? "success" : "error"](response?.data?.msg, { position: "top-center" })
    setFilteredSelectedRows && setFilteredSelectedRows([])
    setToggleClearRows(!toggledClearRows)
    setShowNotifyPopUp(false)
    setIsBulkNotifyLoading(false)
  }

  const [showStatusPopUp, setShowStatusPopUp] = useState(false);
  const [isBulkStatusUpdateLoading, setIsBulkStatusUpdateLoading] = useState(false);
  const [statusPayload, setStatusPayload] = useState({
    renewal_reason_id: 0,
    subscriptionIds: [],
  });

  // useEffect(() => {
  //   renewalReasons?.length ? setStatusPayload(prev => ({ ...prev, renewal_reason_id: renewalReasons[0]?.id })) : setStatusPayload(prev => ({ ...prev, renewal_reason_id: 0 }));
  // }, [renewalReasons, renewalReasons?.length]);

  const handleStatusPopUpClose = () => {
    setShowStatusPopUp(false);
    setIsBulkStatusUpdateLoading(false);
    setStatusPayload({
      renewal_reason_id: 0,
      subscriptionIds: [],
    });
  }

  const handleStatusChange = (event: any) => {
    if (event === null) {
      setStatusPayload(prev => ({ ...prev, renewal_reason_id: 0 }));
    } else {
      setStatusPayload(prev => ({ ...prev, renewal_reason_id: event.value }));
    }
  }

  const handleBulkStatusUpdateSubmit = async (event: any) => {
    event.preventDefault();
    let subscriptionIds = filteredSelectedRows?.map((row: any) => row?.vehicleInactiveSubscription?.id);
    const _statusPayload = { ...statusPayload };
    _statusPayload['subscriptionIds'] = subscriptionIds;
    setIsBulkStatusUpdateLoading(true);
    const response: any = await postRequest(URL_EndPoints(null)?.updateSubscriptionData, _statusPayload, setIsBulkStatusUpdateLoading);
    setFilteredSelectedRows && setFilteredSelectedRows([])
    setToggleClearRows(!toggledClearRows)
    toastNotifier(response?.data, "message", setShowStatusPopUp)
    handleStatusPopUpClose();
  }

  const [showResetLeadPopUp, setShowResetLeadPopUp] = useState(false);
  const [isBulkResetLoading, setIsBulkResetLoading] = useState(false);
  const [resetPayload, setResetPayload] = useState<any>({
    adminId: userid,
    vehicleIds: [],
    adminIds: [],
    areaManagerIds: [],
    subscriptionIds: [],
  });

  const handleResetPopUpClose = () => {
    setAssignPayload({
      adminId: userid,
      vehicleIds: [],
      adminIds: [],
      areaManagerIds: [],
    });
    setShowResetLeadPopUp(false);
    setIsBulkResetLoading(false);
  }

  const handleResetPayloadUpdate = (event: any, name: any) => {
    setResetPayload({ ...resetPayload, [name]: event })
  }

  const handleResetSubmit = async (e: any) => {
    e.preventDefault();
    let ids = filteredSelectedRows?.map((row: any) => row?.id);
    let subscriptionIds = filteredSelectedRows?.map((row: any) => row?.vehicleInactiveSubscription?.id);
    resetPayload['vehicleIds'] = ids.filter((id: number) => id);
    resetPayload['subscriptionIds'] = subscriptionIds.filter((id: number) => id);
    setIsBulkResetLoading(true)
    const response: any = await postRequest(URL_EndPoints(null)?.bulkResetCustomerVehicle, resetPayload, setIsBulkResetLoading)
    setFilteredSelectedRows && setFilteredSelectedRows([])
    setToggleClearRows(!toggledClearRows)
    toast?.[response?.data?.status == 200 ? "success" : "error"](response?.data?.message, { position: "top-center" })
    handleResetPopUpClose();
  }

  const [showAssignBatchPopUp, setShowAssignBatchPopUp] = useState(false);
  const [isBulkBatchAssignLoading, setIsBulkBatchAssignLoading] = useState(false);
  const [batchAssignPayload, setBatchAssignPayload] = useState<any>({
    ids: [],
    type: 'vehicle',
    batch_id: 0,
  });

  const handleAssignBatchPopUpClose = () => {
    setAssignPayload({
      ids: [],
      type: 'vehicle',
      batch_id: 0,
    });
    setShowAssignBatchPopUp(false);
    setIsBulkBatchAssignLoading(false);
  }

  const handleBatchAssignPayloadUpdate = (event: any, name: any) => {
    setBatchAssignPayload({ ...batchAssignPayload, [name]: event });
  }

  const handleBatchAssignSubmit = async (e: any) => {
    e.preventDefault();
    let ids = filteredSelectedRows?.map((row: any) => row?.id);
    batchAssignPayload['ids'] = ids.filter((id: number) => id);
    setIsBulkBatchAssignLoading(true);
    const response: any = await postRequest(URL_EndPoints(null)?.assignBatch, batchAssignPayload, setIsBulkResetLoading)
    setFilteredSelectedRows && setFilteredSelectedRows([])
    setToggleClearRows(!toggledClearRows)
    toast?.[response?.data?.status == 200 ? "success" : "error"](response?.data?.message, { position: "top-center" })
    handleAssignBatchPopUpClose();
  }



  const [isLoadingFilter, setIsLoadingFilter] = useState<boolean>(false)
  const [storeSearchKeywords, setStoreSearchKeywords] = useState<any>("")
  const [dynFilter, setDynFilter] = useState<any>("")

  const setSearchFilter = (event: any, filters: any) => {
    setDynFilter(filters)
    setStoreSearchKeywords(event)
  }

  useEffect(() => {
    let x = setTimeout(async () => {
      setIsLoadingFilter(true)
      storeSearchKeywords && await getFilterDataBySearch(dynFilter, setStoreFilterList, storeFilterList, {
        keyword: storeSearchKeywords,
      })
      setIsLoadingFilter(false)

    }, 2000)

    return () => {
      setIsLoadingFilter(false)
      clearTimeout(x)
    }
  }, [storeSearchKeywords, dynFilter])

  const refreshNow = () => {
    InvokedRendered(handlePagination(search, sortby, direction, page, perPageSize), setPending)
  }

  const [showCampaignContact, setShowCampaignContact] = useState(false);
  const [toggledClearRows, setToggleClearRows] = useState(false);


  let { adminGetSmartFilterList } = useAdminSmartFilterList()
  let { listApiUrl } = useAdminListApiUrl()

  useEffect(() => {
    adminGetSmartFilterList("customerVehicleListPagination")
  }, [])


  
  useEffect(() => {
    let { isFilter, inactiveFilterList } = filterDataOutsource || {}
    !search && !isFilter && InvokedRendered(handlePagination(search, sortby, direction, page, perPageSize), setPending)
    isFilter && InvokedRendered({ ...payload, ["filterData"]: inactiveFilterList }, null)
    isFilter && setPayload({ ...payload, ["filterData"]: inactiveFilterList })
  }, [
    search,
    filterDataOutsource?.inactiveFilterList,
    filterDataOutsource?.isFilter,
  ])


  return (
    <>
      <div>

        <DataTable
          title="Inactive Vehicle List"
          selectableRows
          progressPending={pending}
          columns={reorderColumns(sortedColumns, "vehicleListReorderedItem")}
          data={filterData}
          onColumnOrderChange={cols => storeReorderIndexes(cols, "vehicleListReorderedItem")}
          fixedHeader
          fixedHeaderScrollHeight='auto'
          selectableRowsHighlight
          highlightOnHover
          subHeader
          clearSelectedRows={toggledClearRows}
          // server 
          pagination
          paginationComponentOptions={paginationRowsPerPageOptionsAll}
          paginationRowsPerPageOptions={paginationRowsPerPageOptions}
          sortServer
          paginationServer
          onSort={handleSort}
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          onSelectedRowsChange={handleSelectedRowsChange}
          // server 
          subHeaderComponent={
            <>

              <MainContainerWrapper>
                <LeftBox>
                  <SearchBoxModal search={search} setSearch={setSearch} />
                </LeftBox>
                <RightBox>
                  <Refresh refreshNow={refreshNow} />
                  <ShowHideColumns columns={columns()} sortedColumns={sortedColumns} setSortedColumns={setSortedColumns} />
                  <FilterHandler
                    onChangeHandler={onChangeHandler}
                    actionHandler={actionHandler}
                    getCurrentFilter={getCurrentFilter}
                    removeFilter={removeFilter}
                    payload={payload}
                    Component={DynamicFilter}
                    filterData={filter}
                    OperatorsData={operators}
                    selectedFilterData={selectedFilterData}
                    filterComponents={KeywiseComponents()}
                    storeFilterList={storeFilterList}
                    filterkey={filterkey}
                    setSeacrch={setSearchFilter}
                    isLoadingFilter={isLoadingFilter}
                    listApiUrl={listApiUrl("customerVehicleListPagination")}

                  />
                </RightBox>
              </MainContainerWrapper>

              {
                filteredSelectedRows.length > 0 ? <BulkUtilButtonWrapper>
                  <BulkUtilButton title="Bulk Assign Ticket" filteredSelectedRows={filteredSelectedRows} setShow={setShowAssignPopUp} />
                  <BulkUtilButton title="Bulk WhatsApp Notify Customer" filteredSelectedRows={filteredSelectedRows} setShow={setShowNotifyPopUp} />
                  <BulkUtilButton title="Bulk Reset Vehicles" filteredSelectedRows={filteredSelectedRows} setShow={setShowResetLeadPopUp} />
                  <BulkUtilButton title="Bulk Assign Batch To Vehicles" filteredSelectedRows={filteredSelectedRows} setShow={setShowAssignBatchPopUp} />
                  <BulkUtilButton title="Bulk Status Update Vehicles" filteredSelectedRows={filteredSelectedRows} setShow={setShowStatusPopUp} />
                  <BulkUtilButton title="Bulk Add Contacts to Campaign list" filteredSelectedRows={filteredSelectedRows} setShow={setShowCampaignContact} />
                </BulkUtilButtonWrapper> : <></>
              }

            </>
          }
        />

      </div>


      {
        showCampaignContact ? <AdminBulkAddContactsToCampaignList
          show={showCampaignContact}
          setCloseModal={setShowCampaignContact}
          filteredSelectedRowsIds={filteredSelectedRows?.map((row: any) => row?.vehicleCustomer && row?.vehicleCustomer?.id)?.filter((id: number) => (id !== undefined) && (id !== 0))}
          setToggleClearRows={setToggleClearRows}
          toggledClearRows={toggledClearRows}
          handleRefresh={null}
          userType={"customer"}
        /> : <></>
      }



      {/* <Modal size='lg' show={showAssignPopUp} onHide={handleAssignPopUpClose} >
        <Modal.Header closeButton>
          <Modal.Title>Assign {filteredSelectedRows.length} Tickets to Admins/Supervisors</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleAssignSubmit}>
            <Form.Group className="mb-3" >
              <MultiSelectSearchDetailsDefault
                handleChangeInputData={handleAssignPayloadUpdate}
                HeaderTitle="Select admins"
                SelectData={AdminListData}
                DynamicKey={"first_name"}
                DynamicKey2={"last_name"}
                DynamicKey3={"phone"}
                id={"id"}
                name="admins"
              ></MultiSelectSearchDetailsDefault>
            </Form.Group>
            <Form.Group className="mb-3" >
              <MultiSelectSearchDetailsDefault
                handleChangeInputData={handleAssignPayloadUpdate}
                HeaderTitle="Select supervisor"
                SelectData={AllSupervisroList}
                DynamicKey={"first_name"}
                DynamicKey2={"last_name"}
                DynamicKey3={"phone"}
                id={"id"}
                name="cleaners"
              ></MultiSelectSearchDetailsDefault>
            </Form.Group>
            <Form.Group className="mb-3" >
              <Form.Label>Due Date</Form.Label>
              <Form.Control type="date" value={assignPayload?.dueDate} onChange={(e: any) => handleAssignPayloadUpdate(e?.target?.value, 'dueDate')} />
            </Form.Group>
            <Form.Group className="mb-3" >
              <Form.Check
                type='checkbox'
                label='Bulk Unassign'
                id='bulk_unassign'
                checked={assignPayload.should_unassign}
                onChange={(e: any) => {
                  setAssignPayload({
                    ...assignPayload,
                    should_unassign: e.target.checked
                  })
                }}
              />
            </Form.Group>
            {assignPayload?.should_unassign && <Form.Group className="mb-3" >
              <span className='text-muted'>Note: Keep admins empty to unassign all admins</span>
              <MultiSelectSearchDetailsDefault
                handleChangeInputData={handleAssignPayloadUpdate}
                HeaderTitle="Select admins"
                SelectData={AdminListData}
                DynamicKey={"first_name"}
                DynamicKey2={"last_name"}
                DynamicKey3={"phone"}
                id={"id"}
                name="admins_to_unassign"
              ></MultiSelectSearchDetailsDefault>
            </Form.Group>}
            {assignPayload?.should_unassign && <Form.Group className="mb-3" >
              <span className='text-muted'>Note: Keep supervisors empty to unassign all supervisors</span>
              <MultiSelectSearchDetailsDefault
                handleChangeInputData={handleAssignPayloadUpdate}
                HeaderTitle="Select supervisors"
                SelectData={AllSupervisroList}
                DynamicKey={"first_name"}
                DynamicKey2={"last_name"}
                DynamicKey3={"phone"}
                id={"id"}
                name="cleaners_to_unassign"
              ></MultiSelectSearchDetailsDefault>
            </Form.Group>}
            <Form.Group className="mb-3 p-2" >
              {
                isBulkAssignApiLoading ?
                  <Spinner animation={'border'} />
                  : <Button type='submit' variant="primary">
                    Bulk Assign
                  </Button>
              }
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal> */}


      {
        showAssignPopUp ? <AdminBulkAssignUnassignForm
          show={showAssignPopUp}
          setShow={setShowAssignPopUp}
          filteredSelectedRows={filteredSelectedRowsIds(filteredSelectedRows, "vehicleInactiveTicket.id") || []}
          setFilteredSelectedRows={setFilteredSelectedRows}
          toggledClearRows={toggledClearRows}
          setToggleClearRows={setToggleClearRows}
          refreshNow={() => { }}
          toastMessage="msg"
          initialData={{
            admins: [],
            cleaners: [],
            admins_to_unassign: [],
            cleaners_to_unassign: [],
            dueDate: "",
            should_unassign: false,
          }}
          validationSchema={{
            admins: { required: false, errorMessage: "Admins is Required !" },
            cleaners: { required: false, errorMessage: "Supervisors is Required !" },
            admins_to_unassign: { required: false, errorMessage: "Unassign Admins is Required !" },
            cleaners_to_unassign: { required: false, errorMessage: "Unassign Supervisor is Required !" },
            dueDate: { required: false, errorMessage: "Due Date is Required !" },
            should_unassign: { required: false, errorMessage: "" }
          }}
          keyName="vehicleTypeListAssingForm"
          formTitle={`Assign ${filteredSelectedRows?.length} Tickets to Admins/Supervisors`}
          bulkKeyName="ids"
          formSubmitApiUrl="assignToBulkTickets"
        /> : <></>
      }


      {/* <Modal size='lg' show={showNotifyPopUp} onHide={handleNotifyPopUpClose} >
        <Modal.Header closeButton>
          <Modal.Title>Send WhatsApp Notification to {filteredSelectedRows.length} Customers</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleBulkWhatsappNotifySubmit}>
            <Form.Group className="mb-3" >
              <Form.Select aria-label="Default select example" value={whatsappNotifyPayload.templateName} required name='templateName' onChange={handleWhatsappInputChange}>
                <option value="">Select Template</option>
                {whatsappTemplates?.map((template: any) => <option value={template.name}>{template.name}</option>)}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3 p-2" >
              {
                isBulkNotifyLoading ?
                  <Spinner animation={'border'} />
                  : <Button type='submit' variant="primary">
                    Bulk Send
                  </Button>
              }
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
 */}


      {
        showNotifyPopUp ? <AdminBulkForm
          show={showNotifyPopUp}
          setShow={setShowNotifyPopUp}
          filteredSelectedRows={filteredSelectedRowsIds(filteredSelectedRows, "vehicleCustomer.id") || []}
          setFilteredSelectedRows={setFilteredSelectedRows}
          toggledClearRows={toggledClearRows}
          setToggleClearRows={setToggleClearRows}
          refreshNow={null}
          toastMessage="msg"
          formData={adminWatiNotifyTemplatFormData({ HeaderTitle: "", valueType: "", name: "", errMessage: "", label: "", wa_number_id: 1 })}
          initialData={{
            customerIds: [],
            templateName: "",
          }}
          keyName="inactiveVehicleListBulkForm"
          formTitle={`Send WhatsApp Notification to ${filteredSelectedRows?.length} Customer`}
          bulkKeyName="customerIds"
          formSubmitApiUrl="sendBulkWhatsAppTemplate"
        /> : <></>
      }


      {/* <Modal size='lg' show={showStatusPopUp} onHide={handleStatusPopUpClose} >
        <Modal.Header closeButton>
          <Modal.Title>Update Status of {filteredSelectedRows.length} Vehicles</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleBulkStatusUpdateSubmit}>
            <SingleSelectSearchCategoryDefault
              handleChangeInputData={handleStatusChange}
              HeaderTitle={"Select Status"}
              SelectData={renewalReasons}
              DynamicKey={"name"}
              id={"id"}
              name={"name"}
              defaultData={renewalReasons?.length ? { label: renewalReasons[0]?.name, value: renewalReasons[0]?.id } : {}}
            />
            <Form.Group className="mb-3 p-2" >
              {
                isBulkStatusUpdateLoading ?
                  <Spinner animation={'border'} />
                  : <Button type='submit' variant="primary">
                    Bulk Update
                  </Button>
              }
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal> */}


      {
        showStatusPopUp ? <AdminBulkForm
          show={showStatusPopUp}
          setShow={setShowStatusPopUp}
          filteredSelectedRows={filteredSelectedRowsIds(filteredSelectedRows, "vehicleInactiveSubscription.id") || []}
          setFilteredSelectedRows={setFilteredSelectedRows}
          toggledClearRows={toggledClearRows}
          setToggleClearRows={setToggleClearRows}
          refreshNow={null}
          toastMessage="message"
          formData={
            [
              {
                HeaderTitle: 'Select Status',
                filterApi: 'getSubscriptionRenewalReason',
                label: '',
                valueType: 'select',
                inputType: '',
                displayFields: ['name'],
                valueField: 'id',
                name: 'renewal_reason_id',
                from: '',
                to: '',
                isRequired: true,
                errMessage: 'Status name is required !',
                searchable: false,
                wa_number_id: null,
                userType: null,
                categoryId: 4
              },
            ]
          }
          initialData={{
            subscriptionIds: [],
            renewal_reason_id: 0
          }}
          keyName="inactiveVehicleListBulkForm"
          formTitle={`Update Status of ${filteredSelectedRows.length} Vehicles`}
          bulkKeyName="subscriptionIds"
          formSubmitApiUrl="updateSubscriptionData"
        /> : <></>
      }



      {
        showResetLeadPopUp ? <AdminBulkForm
          show={showResetLeadPopUp}
          setShow={setShowResetLeadPopUp}
          filteredSelectedRows={filteredSelectedRowsIds(filteredSelectedRows, "vehicleInactiveSubscription.id") || []}
          setFilteredSelectedRows={setFilteredSelectedRows}
          toggledClearRows={toggledClearRows}
          setToggleClearRows={setToggleClearRows}
          refreshNow={() => { }}
          toastMessage="message"
          initialData={{
            adminId: userid,
            vehicleIds: filteredSelectedRowsIds(filteredSelectedRows, "") || [],
            adminIds: [],
            areaManagerIds: [],
            subscriptionIds: [],
          }}
          formData={adminAdminSuperFormData}
          keyName="vehicleTypeListResetForm"
          formTitle={`Reset ${filteredSelectedRows?.length} Leads`}
          bulkKeyName="subscriptionIds"
          formSubmitApiUrl="bulkResetCustomerVehicle"
        /> : <></>
      }




      {/* <Modal size='lg' show={showAssignBatchPopUp} onHide={handleAssignBatchPopUpClose} >
        <Modal.Header closeButton>
          <Modal.Title>Assign batch to {filteredSelectedRows.length} Leads</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleBatchAssignSubmit}>
            <Form.Group className="mb-3" >
              <Form.Select aria-label="Default select example" value={batchAssignPayload.batch_id} required name='batch_id' onChange={(e: any) => handleBatchAssignPayloadUpdate(e.target.value, 'batch_id')}>
                <option value="0">Select Batches</option>
                {batches?.map((batch: any) => <option value={batch.id}>{batch.name}</option>)}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3 p-2" >
              {
                isBulkBatchAssignLoading ?
                  <Spinner animation={'border'} />
                  : <Button type='submit' variant="primary">
                    Bulk Assign
                  </Button>
              }
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal> */}



      {
        showAssignBatchPopUp ? <AdminBulkForm
          show={showAssignBatchPopUp}
          setShow={setShowAssignBatchPopUp}
          filteredSelectedRows={filteredSelectedRowsIds(filteredSelectedRows, "") || []}
          setFilteredSelectedRows={setFilteredSelectedRows}
          toggledClearRows={toggledClearRows}
          setToggleClearRows={setToggleClearRows}
          refreshNow={null}
          toastMessage="message"
          formData={adminBatcheFormData}
          initialData={{
            ids: [],
            type: 'vehicle',
            batch_id: 0,
          }}
          keyName="champLeadBatch"
          formTitle={`Assign batch to ${filteredSelectedRows?.length} Batchs`}
          bulkKeyName="ids"
          formSubmitApiUrl="assignBatch"
        /> : <></>
      }


    </>
  )
}


export default VehicleTypeListMain
