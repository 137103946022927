import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
const ClickUpCall = ({ FilteredData }: any) => {
    const Togglling = (value: string) => {
        if (value !== "default") {
            FilteredData?.setdisableWhatsappButton(false)
            FilteredData?.setTemplateid(value)
        }
        else {
            FilteredData?.setdisableWhatsappButton(true)
            FilteredData?.setTemplateid(value)
        }
    }
    return (
        <>
            {FilteredData?.ClickUpShow ? <Modal show={FilteredData?.ClickUpShow} onHide={FilteredData?.handleClose} dialogClassName="modal-small"
                // size="m"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Click Up </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row  mb-3 ms-1 px-2 mt-2 col-12 me-4'>
                        <input
                            type='number'
                            className='form-control  me-2  border mb-2'
                            placeholder='Enter Number Here...'
                            style={{ height: "44px", width: "100%" }}
                            onChange={(e) => FilteredData?.setClickUpValue(e.target.value)}
                            value={FilteredData?.ClickUpValue}
                        />
                        {/* <div className="col-12  mb-3"> */}
                        <select
                            className='form-select  me-2'
                            onChange={(e) => Togglling(e.target.value)}
                        >
                            <option value={"default"}>Select Template</option>
                            <option value={1}>Responding to your inquiry</option>
                            <option value={2}>General information brochure</option>
                        </select>
                        {/* </div> */}
                    </div>
                    <div className='d-flex  align-items-center justify-content-between ms-3 '>
                        <button dir="rtl" className="btn btn-sm btn-success mt-4 " onClick={FilteredData?.clcikUpWhatsappHandler} disabled={FilteredData?.disableWhatsappButton}>
                            {'Whatsapp'}
                        </button>
                        <button dir="rtl" className="btn btn-sm  btn-success mt-4 text-end ms-4" onClick={FilteredData?.clcikUpCallHandler} disabled={FilteredData?.loader ? true : false}>
                            {'Call Now'}
                        </button>
                    </div>
                </Modal.Body>
            </Modal> : ''}
        </>
    )
}
export default ClickUpCall
