import React, { useEffect, useState } from 'react'

import { Button, Tab, Tabs } from 'react-bootstrap'
import IsLoading from '../IsLoading/IsLoading'
import ChatHistory from './components/ChatHistory'
import clsx from 'clsx'
import ChampProfile from './components/ChampProfile'
import { useDispatch, useSelector } from 'react-redux'
import { ChampsCallLogs } from './components/ChampsCallLogs'
import "./styles/Dashboard.css"
import TrainingLists from './Tables/TrainingLists' 
import TrainingAction from './actions/TrainingAction'
import EstimatePoints from './Tables/EstimatePoints'
import ChampBankDetails from './components/ChampBankDetails'
import Old_JobList from '../../modules/Old_JobList/Old_JobList'
import ChampDocuments from './components/ChampDocuments'
import CompanyCustomerMarker from '../../modules/Maps/CompanyCustomerMarker/CompanyCustomerMarker'
import AreaWiseAvailibilityByCleaners from '../../modules/AreaWiseAvailibiltyByCleaners/AreaWiseAvailibilityByCleaners'
import WrapperLayer from './WrapperLayer'
import ChampSchemes from './components/champSchemeSlab/ChampSchemes'
import ChampsAllowance from './components/champsAllowance/ChampsAllowance'
import IsWhatsAppEnabled from '../components/IsWhatsAppEnabled'
import ChampDeviceHashListController from './components/ChampDeviceHashList/ChampDeviceHashListController'
import ChampsFineList from './components/champFineLists/ChampsFineList'
import ChampRewardList from './components/champRewardList/ChampRewardList'
import ChampEasebuzzPaymentTransaction from './components/champEasebuzzPaymentTransaction/ChampEasebuzzPaymentTransaction'
import ChampWeeklyReportList from './components/champWeeklyReportList/ChampWeeklyReportList'
import ChampRewardAndPenalties from './components/champRewardAndPenalties/ChampRewardAndPenalties'
import { languageFullName } from '../CommonData'
import UpdateLanguage from './components/champAreaname/UpdateLanguage'
import CleanerAttendanceLists from '../../modules/CleanersSection/CleanersAttendace/CleanerAttendanceLists'
import { useAdminManagementPermission } from '../../modules/admins/adminUtils'

const ChatDashboardSection = ({ cleanerid }: any) => {
    const { permissionWrapperContextUtil } = useAdminManagementPermission()
    const champsHistoryData = useSelector((store: any) => store.champsReducer.champsHistoryData[cleanerid])
    let champsDetails = champsHistoryData?.ChampDetail

    const [RowData, setRowData] = useState<any>(champsDetails)
    useEffect(() => { setRowData(champsDetails) }, [champsDetails])

    const loadings = useSelector((store: any) => store.champsReducer.loading)
    const [key, setKey] = useState<number>(1)
    const [render, setRender] = useState<boolean>(false)
    const [callHistoryData, setCallHistoryData] = useState<any>([])

    const handleSelect = (key: any) => {
        setKey(key)
        key == 3 ? setRender(true) : setRender(false)
    }

    const [languageUpdateModal, setLanguageUpdateModal] = useState<boolean>(false)

    return (
        <div className='border'>
            {/* header--- */}

            <div className='p-2 border d-flex justify-content-start align-items-center' style={{ height: "40px", width: "100%" }} >
                <div className=' d-flex me-2 cursor-pointer ' onClick={() => setLanguageUpdateModal(true)}>
                    <span >Language:  &nbsp;</span>
                    <span className='badge badge-light-primary text-dark rounded-0'> {languageFullName[champsDetails?.default_language]} <i className="fas fa-edit ms-3 fs-7 text-dark  cursor-pointer"></i></span>
                </div>

                <div className=' d-flex me-2'>
                    <span className=''>isWati Enable:  &nbsp;</span>
                    <IsWhatsAppEnabled
                        row={null}
                        Ids={cleanerid}
                        templateType={2}
                        keyName="champIds"
                        ApiEndUrl="sendBulkWhatsAppTemplateToChamp"
                        to="Champ"
                        IsRequired={false}
                    />
                </div>
            </div>

            {/* header--- */}
            <div className='p-2' >
                <Tabs activeKey={key} onSelect={handleSelect} id='controlled-tab-example'>
                    {
                        permissionWrapperContextUtil("champ-management-dashboard-profile") && <Tab eventKey={1} title='Profile'>
                            {loadings ? (
                                <IsLoading></IsLoading>
                            ) : (
                                <WrapperLayer>
                                    <ChampProfile ChampDetail={champsHistoryData?.ChampDetail} supervisorData={champsHistoryData?.supervisorData} ></ChampProfile>
                                </WrapperLayer>
                            )}
                        </Tab>
                    }

                    {
                        permissionWrapperContextUtil("champ-management-dashboard-chat-history") && <Tab eventKey={2} title='Chat History'>
                            {loadings ? (
                                <IsLoading></IsLoading>
                            ) : (
                                <div style={{ width: "98%" }}>
                                    <div
                                        style={{ maxHeight: "calc(100vh)" }}
                                        className={clsx('scroll-y me-n5')}
                                        data-kt-scroll='true'
                                        data-kt-scroll-dependencies={'#kt_header, #kt_toolbar, #kt_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'}
                                        data-kt-scroll-wrappers={'#kt_content, #kt_chat_messenger_body'}
                                        data-kt-scroll-offset={'-2px'}
                                    >
                                        <ChatHistory
                                            TicketListDatas={champsHistoryData?.result} cleanerid={cleanerid}
                                        ></ChatHistory>
                                    </div>
                                </div>
                            )}
                        </Tab>

                    }
                    {
                        permissionWrapperContextUtil("champ-management-dashboard-call-logs") && <Tab eventKey={3} title='Call Logs'>
                            {loadings ? (
                                <IsLoading></IsLoading>
                            ) : (
                                <ChampsCallLogs
                                    setCallHistoryData={setCallHistoryData}
                                    callHistoryData={callHistoryData}
                                    cleanerid={champsDetails?.id}
                                    keyName={key}
                                ></ChampsCallLogs>
                            )}
                        </Tab>
                    }

                    {
                        permissionWrapperContextUtil("champ-management-dashboard-training-lists") &&
                        <Tab eventKey={5} title='Training Lists'>
                            {loadings ? (
                                <IsLoading></IsLoading>
                            ) : (
                                key == 5 ? <TrainingLists cleanerid={cleanerid} /> : <></>
                            )}
                        </Tab>
                    }

                    {
                        permissionWrapperContextUtil("champ-management-dashboard-action-training") &&
                        <Tab eventKey={7} title='Action Training'>
                            {loadings ? (
                                <IsLoading></IsLoading>
                            ) : (
                                key == 7 ? cleanerid && <TrainingAction cleanerid={cleanerid} /> : <></>
                            )}
                        </Tab>
                    }

                    {
                        permissionWrapperContextUtil("champ-management-dashboard-weekly-payout-report") &&
                        <Tab eventKey={8} title='Weekly reports'>
                            {loadings ? (
                                <IsLoading></IsLoading>
                            ) : (

                                key == 8 ? <WrapperLayer>
                                    <EstimatePoints cleanerid={cleanerid} champsDetails={champsDetails} />
                                </WrapperLayer> : <></>

                            )}
                        </Tab>
                    }


                    {
                        permissionWrapperContextUtil("champ-management-dashboard-weekly-payout-report") &&
                        <Tab eventKey={19} title='Weekly Payout Report'>
                            {loadings ? (
                                <IsLoading></IsLoading>
                            ) : (
                                key == 19 ?
                                    <WrapperLayer>
                                        <ChampWeeklyReportList champsDetails={champsDetails} />
                                    </WrapperLayer>
                                    : <></>

                            )}
                        </Tab>
                    }
                    {
                        permissionWrapperContextUtil("champ-management-dashboard-easebuzz-payment-transactions") &&
                        <Tab eventKey={18} title='Easebuzz Payment Transactions'>
                            {loadings ? (
                                <IsLoading></IsLoading>
                            ) : (
                                key == 18 ?
                                    // <WrapperLayer>
                                    <ChampEasebuzzPaymentTransaction champsDetails={champsDetails} />
                                    // </WrapperLayer>
                                    : <></>
                            )}
                        </Tab>
                    }

                    {
                        permissionWrapperContextUtil("champ-management-dashboard-bank-details") &&
                        <Tab eventKey={9} title='Bank Details'>
                            {loadings ? (
                                <IsLoading></IsLoading>
                            ) : (
                                key == 9 ?
                                    <WrapperLayer>
                                        <ChampBankDetails champsDetails={champsDetails} />
                                    </WrapperLayer>
                                    : <></>
                            )}
                        </Tab>
                    }

                    {
                        permissionWrapperContextUtil("champ-management-dashboard-documents") &&
                        <Tab eventKey={10} title='Documents'>
                            {loadings ? (
                                <IsLoading></IsLoading>
                            ) : (
                                key == 10 ? <ChampDocuments champsDetails={champsDetails} /> : <></>
                            )}
                        </Tab>
                    }
                    {
                        permissionWrapperContextUtil("champ-management-dashboard-map") &&
                        <Tab eventKey={11} title='Map'>
                            {loadings ? (
                                <IsLoading></IsLoading>
                            ) : (
                                <WrapperLayer>
                                    {
                                        key == 11 ? <CompanyCustomerMarker cityId={6} cleanerID={champsDetails?.id} refereshID={Math.random()} champsDetails={champsDetails} from="champDashboard" /> : <></>
                                    }
                                </WrapperLayer>
                            )}
                        </Tab>
                    }
                    {
                        permissionWrapperContextUtil("champ-management-dashboard-area-availability") &&
                        <Tab eventKey={12} title='Area Availibility'>
                            {loadings ? (
                                <IsLoading></IsLoading>
                            ) : (
                                key == 12 ? <WrapperLayer>
                                    {
                                        <AreaWiseAvailibilityByCleaners champId={champsDetails?.id} refereshID={Math.random()} champsDetails={champsDetails} />
                                    }
                                </WrapperLayer> : <></>
                            )}
                        </Tab>
                    }

                    {
                        permissionWrapperContextUtil("champ-management-dashboard-scheme-records") &&
                        <Tab eventKey={13} title='Scheme Records'>
                            {loadings ? (
                                <IsLoading></IsLoading>
                            ) : (
                                key == 13 ? <WrapperLayer>
                                    {
                                        <ChampSchemes refereshID={Math.random()} row={champsDetails} />
                                    }
                                </WrapperLayer> : <></>
                            )}
                        </Tab>
                    }
                    {
                        permissionWrapperContextUtil("champ-management-dashboard-allowance") &&
                        <Tab eventKey={14} title='Allowance'>
                            {loadings ? (
                                <IsLoading></IsLoading>
                            ) : (
                                key == 14 ? <WrapperLayer>
                                    {
                                        <ChampsAllowance refereshID={Math.random()} row={champsDetails} />
                                    }
                                </WrapperLayer> : <></>
                            )}
                        </Tab>
                    }
                    {
                        permissionWrapperContextUtil("champ-management-dashboard-job-list") &&
                        <Tab eventKey={4} title='Job List'  >
                            {loadings ? (
                                <IsLoading></IsLoading>
                            ) : (

                                <WrapperLayer>
                                    {
                                        champsHistoryData?.ChampDetail?.id && key == 4 ? (
                                            <Old_JobList
                                                cleaner_id={champsHistoryData?.ChampDetail?.id}
                                            />
                                        ) : (
                                            <div></div>
                                        )
                                    }
                                </WrapperLayer>
                            )}
                        </Tab>
                    }
                    {
                        permissionWrapperContextUtil("champ-management-dashboard-hash-list") &&
                        <Tab eventKey={15} title='Hash Lists'>
                            {loadings ? (
                                <IsLoading></IsLoading>
                            ) : (
                                key == 15 ? <WrapperLayer>
                                    {
                                        <ChampDeviceHashListController />
                                    }
                                </WrapperLayer> : <></>
                            )}
                        </Tab>
                    }

                    {
                        permissionWrapperContextUtil("champ-management-dashboard-fine-list") &&
                        <Tab eventKey={16} title='Fine List'>
                            {loadings ? (
                                <IsLoading></IsLoading>
                            ) : (
                                key == 16 ? <WrapperLayer>
                                    {
                                        <ChampsFineList cleaner_id={champsHistoryData?.ChampDetail?.id} />
                                    }
                                </WrapperLayer> : <></>
                            )}
                        </Tab>
                    }
                    {
                        permissionWrapperContextUtil("champ-management-dashboard-reward-list") &&
                        <Tab eventKey={17} title='Reward List'>
                            {loadings ? (
                                <IsLoading></IsLoading>
                            ) : (
                                key == 17 ? <WrapperLayer>
                                    {
                                        <ChampRewardList cleaner_id={champsHistoryData?.ChampDetail?.id} type={champsHistoryData?.ChampDetail} />
                                    }
                                </WrapperLayer> : <></>
                            )}
                        </Tab>
                    }

                    {
                        permissionWrapperContextUtil("champ-management-dashboard-reward-and-penalties") &&
                        <Tab eventKey={20} title='Reward And Penalties'>
                            {
                                key == 20 ? <WrapperLayer>
                                    {
                                        <ChampRewardAndPenalties cleaner_id={champsHistoryData?.ChampDetail?.id} />
                                    }
                                </WrapperLayer> : <></>
                            }
                        </Tab>
                    }

                    {
                        permissionWrapperContextUtil("champ-management-dashboard-attendance-list") &&

                        <Tab eventKey={21} title='Attendance List'>
                            {
                                key == 21 ? <WrapperLayer>
                                    {
                                        <CleanerAttendanceLists filterDataOutSource={{
                                            isFilter: true,
                                            payload: { cleanerid: champsHistoryData?.ChampDetail?.id }
                                        }} />
                                    }
                                </WrapperLayer> : <></>
                            }
                        </Tab>
                    }


                </Tabs>
            </div>

            {
                languageUpdateModal && <UpdateLanguage
                    show={languageUpdateModal}
                    setCloseModal={setLanguageUpdateModal}
                    row={RowData}
                    setRowData={setRowData}
                />
            }
        </div>
    )
}

export default ChatDashboardSection
