import React, { useEffect, useState } from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import { useDispatch } from 'react-redux'
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import { Button, Col, Form, Row } from 'react-bootstrap'
import SweetAlertChat from '../../SweetAlert/SweetAlertChat';
import DialogBoxGlobal from '../../Dialogbox/DialogBoxGlobal';
import IsLoading from '../../IsLoading/IsLoading';
import UpdateAddressPincode from '../../SweetAlert/UpdateAddressPincode';
import { URL_EndPoints } from '../../Server/URL_EndPoints';
import { getRequest, postRequest } from '../../Server/Requests';
import UpdateStaus from '../../../modules/ConstantsComponents/ActivePaidSubscriptions/UpdateStaus';
import ChangTimeSlots from '../../../modules/ConstantsComponents/ActivePaidSubscriptions/ChangeTimeSlots';
import ChangeFullCleaningDay from '../../../modules/ConstantsComponents/ActivePaidSubscriptions/ChangeFullCleaningDay';
import SingleSelectSearchCategory from '../../Select/SingleSelectSearchCategory';
import { toast } from 'react-toastify';
import { ColorRing } from 'react-loader-spinner';
import RemoveDublicateVehicle from '../../SweetAlert/RemoveDublicateVehicle';
import CompleteSubscription from '../../SweetAlert/CompleteSubscription';
import CreditCoinsForm from './Forms/CreditCoinsForm';
import RegnerateJob from './Forms/RegnerateJob';
import WaveOff from '../../SweetAlert/WaveOff';
import GetRazorpaySubscriptionOrder from './GetRazorpaySubscriptionOrder';
import UpdateAddress from '../../components/UpdateAddress';
import ToggleStatusAlertAction from '../../SweetAlert/ToggleStatusAlertAction';
import AdminForm from '../../../modules/admins/adminForms/AdminForm';
import ChangeStartDateForm from './Forms/ChangeStartDateForm';
import PermissionContextProvider from '../../../modules/admins/adminComponents/PermissionContextProvider';
import { adminMenuActionPermissionKeys } from '../../../modules/admins/adminConstant';
function DropdownMenu({ subscriptions, subscriptionid, random }: any) {
    const dispatch = useDispatch()
    const [isStatusOpen, setStatusOpen] = React.useState<boolean>(false)
    const [IsfullCleanDayOpen, setIsfullCleanDayOpen] = React.useState<boolean>(false)
    const [isRazorpayRefundStatus, setIsRazorpayRefundStatus] = React.useState<boolean>(false)
    const [disablerenders, setdisablerenders] = React.useState<any>(null)
    const [FullDaySet, SetfullDay] = React.useState<any>(null)
    const [timeSlotOpen, setTimeSlotOpen] = React.useState<boolean>(false)
    const [waveOffModal, setWaveOffModal] = React.useState<any>(null)
    const [razorpayOrderModal, setRazorpayOrderModal] = React.useState<any>(false)
    const [removedublicateVehicle, setremovedublicateVehicle] = React.useState<any>(null)
    const [vehicleid, setvehicleid] = React.useState<any>("")
    const [selectedId, setSelected] = React.useState<number | string>('')
    const [refundData, setRefundData] = React.useState<any>([])
    const [isRefundDataLoading, setIsRefundDataLoading] = React.useState<boolean>(false)
    const [showExtendPopUp, setShowExtendPopUp] = React.useState<boolean>(false)
    const [showChangeStatusPopUp, setShowChangeStatusPopUp] = React.useState<boolean>(false)
    const [updateAddressModal, setUpdateAddressModal] = React.useState(false)

    const [extendSubscriptionResult, setExtendSubscriptionResult] = React.useState<any>(null)
    const [masterReasons, setMasterReasons] = React.useState<any>([])
    const [updateStatusButtonLoading, setUpdateStatusButtonLoading] = React.useState<any>(false)
    const [completeSubscriptionShow, setcompleteSubscriptionShow] = React.useState<any>(null)
    const [razorpayAmount, setRazorpayAmount] = React.useState<any>(0)
    const [updateSubscriptionStatusData, setUpdateSubscriptionStatusData] = React.useState<any>({
        subscriptionid: subscriptionid,
        isactivestatus: 0,
        reason_id: 0,
        // inactive_date : inactive_date,
        start_date: '',
        end_date: '',
        refund: 0,
        refund_amount: 0,
    })



    useEffect(() => {
        setUpdateSubscriptionStatusData({ ...updateSubscriptionStatusData, ["subscriptionid"]: subscriptionid })
    }, [subscriptionid])


    const [refundDataPayload, setRefundDataPayload] = React.useState<any>({
        subscriptionid: subscriptionid,
        refund_amount: 0,
    })




    const [refundPopup, setRefundPopup] = React.useState<any>(false)
    const [coinCreditPopUp, setCoinCreditPopUp] = React.useState<any>(false)
    const [regnerateJobPopup, setRegnerateJobPopup] = React.useState<any>(null)
    const [startDateChangePopup, setStartDateChangePopup] = React.useState<any>(false)
    const [changeStartDateLoader, setChangeStartDateLoader] = React.useState<any>(false)
    const [changeStartDatePayload, setChangeStartDatePayload] = React.useState<any>({
        startDate: ''
    })
    const handleclose = () => {
        setRefundPopup(false)
        setIsRazorpayRefundStatus(false)
        setTimeSlotOpen(false)
        setIsfullCleanDayOpen(false)
        setCoinCreditPopUp(false)
        // setRegnerateJobPopup(false)
    }
    const OpenJobList = (
        JobListSubscriptionid: any,
        JobListEventKey: any,
        JobListRender: any,
        enddate: any,
        startdate: any
    ) => {
        dispatch({
            type: 'CHAT_JOB_LIST_EVENT_KEY',
            payload: {
                JobListRender,
                JobListEventKey,
                JobListSubscriptionid,
                JobListDate: {
                    startdate,
                    enddate,
                },
            },
        })
    }
    const handleReassign = (subscriptionid: any) => {
        dispatch({
            type: 'CHAMP_AVALITY', payload: {
                champAvailty: subscriptionid,
                champAvailtyRender: Math.random(),
                from: "subscriptionDataChat"
            }
        })
    }

    const handleChangeStartDateSubmit = async (e: any) => {
        try {
            e.preventDefault()
            setChangeStartDateLoader(true)
            const payload = {
                ...changeStartDatePayload,
                subscriptionId: subscriptionid,
            }
            const res = await postRequest(URL_EndPoints()?.changeSubscriptionStartDate, payload, setChangeStartDateLoader)
            const isSuccess = res.data?.status == 200
            toast[isSuccess ? 'success' : 'info'](res.data?.message)
            if (isSuccess) {
                setChangeStartDatePayload({
                    startDate: ''
                })
                setStartDateChangePopup(false)
                dispatch({
                    type: 'CHANGE_STATUS', payload: {
                        Change_Status: subscriptionid,
                        Change_Statu_Render: Math.random()
                    }
                })
            }
        } catch (error) {
            toast.error('Something went wrong')
        } finally {
            setChangeStartDateLoader(false)
        }
    }

    // update status herer
    const handleStatusOpen = (subscriptionid: any) => {
        setStatusOpen(true)
        setSelected(subscriptionid)
    }
    // update timeslots herer
    const handleTimeSlotOpen = (subscriptionid: any) => {
        setTimeSlotOpen(true)
        setSelected(subscriptionid)
    }

    const WaveoffHandler = () => {
        setWaveOffModal(Math.random())
    }
    const getSubscriptionRazorpayOrder = () => {
        setRazorpayOrderModal(true)
    }




    const RemoveSublicateVehicle = (vehicleid: any) => {
        setvehicleid(vehicleid)
        let randoms = Math.random() + subscriptionid
        setremovedublicateVehicle(randoms)
    }
    const ChangeFullCleaningDays = (subscriptionid: any) => {
        setIsfullCleanDayOpen(true)
        setSelected(subscriptionid)
    }
    const updateAddressPincode = (subscriptionid: any) => {
        let randoms = Math.random() + subscriptionid
        SetfullDay(randoms)
    }
    // handleDisable auto pay timeslots herer
    const HandleDisableAutoPay = (subscriptionid: any) => {
        let randoms = Math.random() + subscriptionid
        setdisablerenders(randoms)
    }
    // to close form  all one by one  herer
    const handleCloseModal = () => {
        setIsfullCleanDayOpen(false)
        setStatusOpen(false)
        setTimeSlotOpen(false)
        setRazorpayOrderModal(false)
        setUpdateAddressModal(false)
    }
    const handleRefundStatus = async (subscriptionId: any, customerName: any = "") => {
        setIsRefundDataLoading(true)
        setIsRazorpayRefundStatus(true)
        const refundStatusRazorpay = await postRequest(URL_EndPoints()?.getRefundStatus, {
            subscriptionid: subscriptionid
        }, null)
        setRefundData(refundStatusRazorpay?.data?.data)
        setIsRefundDataLoading(false)
    }
    const handleUpdatePaymentStatus = async (subscriptionId: any, customerName: any = "") => {
        let response = await postRequest(URL_EndPoints()?.checkRazorpayStatus, {
            subscriptionid: subscriptionid
        }, null)
        if (response.status == 200) {
            toast('Payment status updated successfully.')
        } else {
            toast(response?.message)
        }
    }
    const handleExtendSubscription = async (subscriptionId: any) => {
        const extendSubscription = await postRequest(URL_EndPoints()?.getSubscriptionDataForExtend, {
            subscriptionId: subscriptions?.id
        }, null)
        if (extendSubscription.status == 200) {
            setExtendSubscriptionResult(extendSubscription.data.data)
            setShowExtendPopUp(true)
        }
    }
    const handleChangeStatus = async (subscriptionId: any) => {
        setShowChangeStatusPopUp(true)

        try {
            const response = await postRequest(URL_EndPoints(null)?.getRazorpayPayment, { subscription_id: updateSubscriptionStatusData?.subscriptionid }, null)
            if (response?.status == 200) {
                setRazorpayAmount(response.data.data?.amount ? response.data.data?.amount / 100 : 0)
            }
        } catch (error) {

        }
    }
    const changeAddressHandler = async (subscriptionId: any) => {
        setUpdateAddressModal(true)
    }


    useEffect(() => {
        async function getMasterReason() {
            const response = await postRequest(URL_EndPoints(null)?.getMasterReasonsForSubscription, {}, null)
            if (response.status == 200) {
                setMasterReasons(response?.data?.data)
            }
        }
        showChangeStatusPopUp && getMasterReason()
    }, [showChangeStatusPopUp])

    const handleChangeUpdateStatusData = (e: any, name = null) => {
        if (name) {
            setUpdateSubscriptionStatusData({
                ...updateSubscriptionStatusData, [name]: e.value,
            })
        } else {
            if (e?.target?.name == "refund") {
                setUpdateSubscriptionStatusData({
                    ...updateSubscriptionStatusData,
                    [e?.target?.name]: e?.target?.checked == true ? 1 : 0
                })
            } else {
                setUpdateSubscriptionStatusData({
                    ...updateSubscriptionStatusData, [e.target.name]: e.target.name == "reason_id" || e.target.name == "isactivestatus" || e.target.name == "refund_amount" ? +e.target.value : e.target.value,
                })
            }
        }
    }
    const handleChangeUpdateStatusSubmit = async (e: any) => {
        e.preventDefault();

        if (updateSubscriptionStatusData?.subscriptionid != 0 && updateSubscriptionStatusData?.isactivestatus != 0
            && updateSubscriptionStatusData?.reason_id != 0 && updateSubscriptionStatusData?.start_date != ''
            && updateSubscriptionStatusData?.end_date != '') {
            if (updateSubscriptionStatusData?.isactivestatus == 2) {
                updateSubscriptionStatusData.refund = 0
                updateSubscriptionStatusData.refund_amount = 0
            }
            setUpdateStatusButtonLoading(true)
            const response = await postRequest(URL_EndPoints(null)?.updateSubscriptionStatus, updateSubscriptionStatusData, null)
            if (response?.status == 200) {
                toast('Status changed successfully.')
                dispatch({
                    type: 'CHANGE_STATUS', payload: {
                        Change_Status: updateSubscriptionStatusData?.subscriptionid,
                        Change_Statu_Render: Math.random()
                    }
                })
                // const Change_Status = useSelector((store:any)=>store.RefreshPage.Change_Status)
            } else {
                toast(response?.message)
                // dispatch({
                //     type: 'CHANGE_STATUS', payload: {
                //         Change_Status: updateSubscriptionStatusData?.subscriptionid,
                //         Change_Statu_Render: Math.random()
                //     }
                // })
            }
            setUpdateStatusButtonLoading(false)
        }
    }
    const handleChangeRefundPayload = async (e: any, name = null) => {
        setRefundDataPayload({
            ...refundDataPayload,
            [e?.target?.name]: e?.target?.value
        })
    }

    const handleInitiateCreditCoin = async (subscriptionId: any) => {
        setCoinCreditPopUp(true)
    }

    const regnerateJobHandler = async (subscriptionId: any) => {
        setRegnerateJobPopup(true)
    }
    const handleInitiateRefund = async (subscriptionId: any) => {
        setRefundPopup(true)
        setIsRefundDataLoading(true)
        const refundStatusRazorpay = await postRequest(URL_EndPoints()?.getRefundStatus, {
            subscriptionid: subscriptionId
        }, null)
        setRefundData(refundStatusRazorpay?.data?.data)
        setIsRefundDataLoading(false)
        const response: any = postRequest(URL_EndPoints(null)?.getRazorpayPayment, { subscription_id: updateSubscriptionStatusData?.subscriptionid }, null)
        if (response?.status == 200) {
            setRazorpayAmount(response?.data.data?.amount ? response?.data.data?.amount / 100 : 0)
        }
    }
    const handleRefundForm = async (e: any, singleSubId: any) => {
        e.preventDefault()
        if (refundDataPayload.refund_amount > 0) {
            setUpdateStatusButtonLoading(true)
            const response = await postRequest(URL_EndPoints(null)?.initateRefund, {
                subscriptionid: singleSubId,
                refund_amount: refundDataPayload.refund_amount,
            }, null)
            if (response?.status == 200) {
                handleRefundStatus(subscriptionid)
                toast('Refund inititated successfully.')
                dispatch({
                    type: 'INITIATE_REFUND', payload: {
                        Intiate_Refund: refundDataPayload.refund_amount,
                        Intiate_Refund_Render: Math.random()
                    }
                })
                // const Intiate_Refund_rEfresh = useSelector((store:any)=>store.RefreshPage.Intiate_Refund)
            } else {

                toast.error(response?.response && response?.response?.data && response?.response?.data?.data && response?.response?.data?.data?.error && response?.response?.data?.data?.error?.description ? response?.response?.data?.data?.error?.description : response.message)
            }
        } else {
            toast('Please enter a valid refund amount!')
        }
        setUpdateStatusButtonLoading(false)
    }


    const CompleteSubscriptionHandler = async (subscriptionId: any) => {
        setcompleteSubscriptionShow(Math.random())
    }


    const [formTimeSloteData, setFormTimeSloteData] = useState<any>([])
    const [formDefaultTimeSlote, setFormDefaultTimeSlote] = useState<any>(null)
    const getChampbuddyActiveSupervisor = async () => {
        const formTimeSloteData = await getRequest(URL_EndPoints("getCustomerTimeslots")?.getCustomerTimeslots, null)
        setFormTimeSloteData(formTimeSloteData?.data?.data)
    }

    useEffect(() => {
        timeSlotOpen == true && getChampbuddyActiveSupervisor()
    }, [timeSlotOpen])

    // useEffect(() => {
    //     let prevTimeslote: any = subscriptions?.timeslotCustomerSelectedDetail
    //     const filterTimeSlote = formTimeSloteData?.filter((ele: any) => prevTimeslote?.id == ele?.id)?.map((ele: any) => ({ label: ele?.name, value: ele?.id }))[0] || null
    //     timeSlotOpen && setFormDefaultTimeSlote(filterTimeSlote ? filterTimeSlote : null)
    // }, [formTimeSloteData, subscriptions?.timeslotCustomerSelectedDetail?.id, timeSlotOpen])


    return (
        <>

            {
                updateAddressModal &&
                <UpdateAddress show={updateAddressModal} handleClose={handleCloseModal} subscriptionId={subscriptionid} customerId={subscriptions?.customerid} />
            }


            <Dropdown>
                <Dropdown.Toggle variant='white' id='dropdown-basic'>
                    <i className='bi bi-three-dots fs-2 '></i>
                </Dropdown.Toggle>
                <Dropdown.Menu>

                    <PermissionContextProvider keyName={adminMenuActionPermissionKeys["active-subscription-assign-cleaner"]}>
                        {
                            subscriptions?.cleanerid == 0 ? <></> :
                                <Dropdown.Item onClick={() => handleReassign(subscriptionid)} id="kt_drawer_chat3_toggle3">Assign Cleaner</Dropdown.Item>

                        }
                    </PermissionContextProvider>

                    <PermissionContextProvider keyName={adminMenuActionPermissionKeys["active-subscription-remove-dublicate-vahicle"]}>
                        <Dropdown.Item href='#' onClick={() => RemoveSublicateVehicle(subscriptions?.vehicleid)}>
                            Remove Dublicate Vehicle
                            <RemoveDublicateVehicle
                                confirm='Yes'
                                cancel='No'
                                payloads={{
                                    render: removedublicateVehicle,
                                    payloadData: {
                                        vehicleid: vehicleid,
                                    },
                                    successTitle: 'Changes Are Saved Successfully ',
                                    FailedTitle: 'Changes Cancelled ',
                                    reference: "subcriptionHistory"
                                }}
                                localKey={true}
                            ></RemoveDublicateVehicle>
                        </Dropdown.Item>
                    </PermissionContextProvider>

                    <PermissionContextProvider keyName={adminMenuActionPermissionKeys["active-subscription-change-customer-timeslot"]}>
                        <Dropdown.Item href='#' onClick={() => handleTimeSlotOpen(subscriptionid)}>
                            Change Customer Timeslot
                        </Dropdown.Item>
                    </PermissionContextProvider>


                    {
                        random == "history" && <Dropdown.Item href='#' onClick={() => WaveoffHandler()}>
                            Waveoff
                        </Dropdown.Item>
                    }

                    <PermissionContextProvider keyName={adminMenuActionPermissionKeys["active-subscription-get-razorpay-payment"]}>
                        <Dropdown.Item href='#' onClick={() => getSubscriptionRazorpayOrder()}>
                            Get Razorpay Payments
                        </Dropdown.Item>

                    </PermissionContextProvider>

                    <PermissionContextProvider keyName={adminMenuActionPermissionKeys["active-subscription-change-full-cleaning-day"]}>
                        <Dropdown.Item href='#' onClick={() => ChangeFullCleaningDays(subscriptionid)}>
                            Change Full Cleaning Day
                        </Dropdown.Item>

                    </PermissionContextProvider>

                    <PermissionContextProvider keyName={adminMenuActionPermissionKeys["active-subscription-update-address-pincode"]}>
                        <Dropdown.Item href='#' onClick={() => updateAddressPincode(subscriptionid)}>
                            Update Address Pincode
                            <UpdateAddressPincode
                                confirm='Yes'
                                cancel='No'
                                payloads={{
                                    render: FullDaySet,
                                    payloadData: {
                                        addressid: subscriptions?.address_id,
                                    },
                                    successTitle: 'Changes Saved Successfully ',
                                    FailedTitle: 'Changes Cancelled ',
                                }}
                                localKey={true}
                            ></UpdateAddressPincode>
                        </Dropdown.Item>
                    </PermissionContextProvider>

                    <PermissionContextProvider keyName={adminMenuActionPermissionKeys["active-subscription-job-list"]}>
                        <Dropdown.Item
                            onClick={() =>
                                OpenJobList(
                                    subscriptionid,
                                    8,
                                    Math.random(),
                                    subscriptions?.enddate,
                                    subscriptions?.startdate
                                )
                            }
                        >
                            Job-List
                        </Dropdown.Item>
                    </PermissionContextProvider>

                    <PermissionContextProvider keyName={adminMenuActionPermissionKeys["active-subscription-disable-auto-pay"]}>
                        <Dropdown.Item href='#' onClick={() => HandleDisableAutoPay(subscriptionid)}>
                            Disable Auto Pay
                            <SweetAlertChat
                                confirm='Yes'
                                cancel='No'
                                payloads={{
                                    render: disablerenders,
                                    payloadData: {
                                        subscriptionid: subscriptionid,
                                    },
                                    successTitle: 'Changes Are Saved Successfully ',
                                    FailedTitle: 'Changes Cancelled ',
                                }}
                                localKey={true}
                            ></SweetAlertChat>
                        </Dropdown.Item>
                    </PermissionContextProvider>

                    <PermissionContextProvider keyName={adminMenuActionPermissionKeys["active-subscription-get-refund-status"]}>
                        <Dropdown.Item href='#' onClick={() => handleRefundStatus(subscriptionid)}>
                            Get Refund Status
                        </Dropdown.Item>
                    </PermissionContextProvider>

                    <PermissionContextProvider keyName={adminMenuActionPermissionKeys["active-subscription-extend-subscription"]}>
                        <Dropdown.Item href='#' onClick={() => handleExtendSubscription(subscriptionid)}>
                            Extend Subscription
                        </Dropdown.Item>
                    </PermissionContextProvider>

                    <PermissionContextProvider keyName={adminMenuActionPermissionKeys["active-subscription-change-status"]}>
                        <Dropdown.Item href='#' onClick={() => handleChangeStatus(subscriptionid)}>
                            Change Status
                        </Dropdown.Item>
                    </PermissionContextProvider>

                    <PermissionContextProvider keyName={adminMenuActionPermissionKeys["active-subscription-change-address"]}>

                        <Dropdown.Item href='#' onClick={() => changeAddressHandler(subscriptionid)}>
                            Change Address
                        </Dropdown.Item>
                    </PermissionContextProvider>

                    <PermissionContextProvider keyName={adminMenuActionPermissionKeys["active-subscription-update-payment-status"]}>
                        <Dropdown.Item href='#' onClick={() => handleUpdatePaymentStatus(subscriptionid)}>
                            Update Payment Status
                        </Dropdown.Item>
                    </PermissionContextProvider>

                    <PermissionContextProvider keyName={adminMenuActionPermissionKeys["active-subscription-initiate-refund"]}>
                        <Dropdown.Item href='#' onClick={() => handleInitiateRefund(subscriptionid)}>
                            Initiate Refund
                        </Dropdown.Item>
                    </PermissionContextProvider>

                    <PermissionContextProvider keyName={adminMenuActionPermissionKeys["active-subscription-credit-coin"]}>
                        <Dropdown.Item href='#' onClick={() => handleInitiateCreditCoin(subscriptionid)}>
                            Credit Coin
                        </Dropdown.Item>
                    </PermissionContextProvider>

                    <PermissionContextProvider keyName={adminMenuActionPermissionKeys["active-subscription-regenerate-job"]}>
                        <Dropdown.Item href='#' onClick={() => setRegnerateJobPopup(Math.random())}>
                            Regenerate Job
                        </Dropdown.Item>
                    </PermissionContextProvider>

                    <PermissionContextProvider keyName={adminMenuActionPermissionKeys["active-subscription-change-start-date"]}>
                        <Dropdown.Item href='#' onClick={() => setStartDateChangePopup(true)}>
                            Change Start Date
                        </Dropdown.Item>
                    </PermissionContextProvider>

                    {
                        random == "active" && subscriptions?.frequencyid == 4 && <Dropdown.Item href='#' onClick={() => CompleteSubscriptionHandler(subscriptionid)}>
                            Complete Subscription
                            <CompleteSubscription
                                confirm='Yes'
                                cancel='No'
                                payloads={{
                                    render: completeSubscriptionShow,
                                    payloadData: {
                                        orderid: subscriptionid,
                                    },

                                    successTitle: 'Changes Are Saved Successfully ',
                                    FailedTitle: 'Changes Cancelled ',
                                }}
                                localKey={true}
                            ></CompleteSubscription>
                        </Dropdown.Item>
                    }
                </Dropdown.Menu>
            </Dropdown>
            {/* pop up forms are here to perform custom actions   */}
            {/* {timeSlotOpen && (
                <DialogBoxGlobal
                    handleCloseForm={handleCloseModal}
                    PopUpPostFormOpen={timeSlotOpen}
                    ParentData={{ handleclose }}
                    selectedId={selectedId}
                    Components={ChangTimeSlots}
                />
            )} */}

            {
                timeSlotOpen ? <AdminForm
                    show={timeSlotOpen}
                    setShow={setTimeSlotOpen}
                    refreshNow={() => { }}
                    toastMessage="msg"
                    formData={[
                        {
                            HeaderTitle: 'Select Timeslote',
                            filterApi: 'staticFormData',
                            label: {
                                show: true,
                                name: 'Select Timeslote',
                            },
                            valueType: 'select',
                            inputType: '',
                            displayFields: ['name'],
                            valueField: 'id',
                            name: 'timeslot',
                            from: '',
                            to: '',
                            isRequired: true,
                            errMessage: 'Timeslot is required !',
                            searchable: true,
                            wa_number_id: null,
                            userType: null,
                        },
                    ]}
                    staticFormData={formTimeSloteData}
                    initialData={{
                        subscriptionId: subscriptionid,
                        timeslot: subscriptions?.timeslotCustomerSelectedDetail?.id ? subscriptions?.timeslotCustomerSelectedDetail?.id : null,
                    }}

                    prevInitialData={{
                        item: subscriptions?.timeslotCustomerSelectedDetail?.name ? {
                            label: subscriptions?.timeslotCustomerSelectedDetail?.name, value: subscriptions?.timeslotCustomerSelectedDetail?.id
                        } : null
                    }}
                    dynamicApiData={{
                        getCustomerTimeslots: {
                            url: 'getCustomerTimeslots',
                            method: 'GET',
                            isQuery: null,
                            isParams: null,
                            isBody: null,
                        }
                    }}
                    keyName="updateCustomerTimesloteActiveSubscriptionForm"
                    formTitle={`Update Customer Timeslote`}
                    formSubmitApiUrl="updateSubscriptionCustomerTimeslot"
                /> : <></>
            }



            {/* update status form   */}
            {isStatusOpen && (
                <DialogBoxGlobal
                    handleCloseForm={handleCloseModal}
                    PopUpPostFormOpen={isStatusOpen}
                    ParentData={null}
                    selectedId={selectedId}
                    Components={UpdateStaus}
                />
            )}
            {/* full clean day form  */}
            {IsfullCleanDayOpen && (
                <DialogBoxGlobal
                    handleCloseForm={handleCloseModal}
                    PopUpPostFormOpen={IsfullCleanDayOpen}
                    ParentData={{ handleclose }}
                    selectedId={selectedId}
                    Components={ChangeFullCleaningDay}
                />
            )}
            <Modal size="lg" show={isRazorpayRefundStatus} onHide={() => setIsRazorpayRefundStatus(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Refund Status</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        isRefundDataLoading ?
                            <IsLoading></IsLoading> :
                            refundData?.items?.length > 0 ?
                                <Table responsive>
                                    <thead>
                                        <tr>
                                            <th>Subscription Id</th>
                                            <th>Refund Id</th>
                                            <th>Status</th>
                                            <th>Payment Id</th>
                                            <th>Amount</th>
                                            <th>Process</th>
                                            <th>Created At</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {refundData?.items?.map((item: any) =>
                                            <>
                                                <tr>
                                                    <td>{subscriptions?.id}</td>
                                                    <td>{item?.id}</td>
                                                    <td>{item?.status}</td>
                                                    <td>{item?.payment_id}</td>
                                                    <td>₹{item?.amount / 100}</td>
                                                    <td>{item?.speed_processed}</td>
                                                    <td>{new Date(item?.created_at * 1000).toLocaleString()}</td>
                                                </tr>
                                            </>
                                        )}
                                    </tbody>
                                </Table>
                                : "No Data"
                    }
                </Modal.Body>
            </Modal>
            <Modal size="lg" show={showExtendPopUp} onHide={() => setShowExtendPopUp(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Extend Subscription</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        extendSubscriptionResult?.is_direct_extend ?
                            <>
                                <Form>
                                    <Form.Group className="mb-3" controlId="formBasicStartDate">
                                        <Form.Label>Start Date</Form.Label>
                                        <Form.Control type="date" placeholder="Start Date" />
                                        {/* <Form.Text className="text-muted">
                                            We'll never share your email with anyone else.
                                        </Form.Text> */}
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formBasicEndDate">
                                        <Form.Label>End Date</Form.Label>
                                        <Form.Control type="date" placeholder="End Date" />
                                        {/* <Form.Text className="text-muted">
                                            We'll never share your email with anyone else.
                                        </Form.Text> */}
                                    </Form.Group>
                                    {/*  */}
                                    <Button variant="primary" type="submit">
                                        Extend
                                    </Button>
                                </Form>
                            </> :
                            <>
                                <Form>
                                    <Form.Group className="mb-3" controlId="formBasicService">
                                        <Form.Label>Services</Form.Label>
                                        <Form.Control type="number" placeholder="No. Of Services" />
                                        {/* <Form.Text className="text-muted">
                                            We'll never share your email with anyone else.
                                        </Form.Text> */}
                                    </Form.Group>
                                    {/*  */}
                                    <Button variant="primary" type="submit">
                                        Add
                                    </Button>
                                </Form>
                            </>
                    }
                </Modal.Body>
            </Modal>

            <ChangeStartDateForm
                startDateChangePopup={startDateChangePopup}
                setStartDateChangePopup={setStartDateChangePopup}
                changeStartDateLoader={changeStartDateLoader}
                handleChangeStartDateSubmit={handleChangeStartDateSubmit}
                setChangeStartDatePayload={setChangeStartDatePayload}
            />

            <Modal size="lg" show={showChangeStatusPopUp} onHide={() => setShowChangeStatusPopUp(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Change Subscription Status: <br /><p style={{ fontSize: '13px' }}>Maxmimun Refund Amount: ₹{razorpayAmount}</p></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        <>
                            <Form onSubmit={handleChangeUpdateStatusSubmit}>
                                <Form.Group className="mb-3" controlId="formBasicService">
                                    <Form.Label>Stop Type</Form.Label>
                                    <Form.Select name='isactivestatus' onChange={handleChangeUpdateStatusData} required>
                                        <option value={0} selected disabled>Select Status</option>
                                        <option value={1}>Inactive</option>
                                        <option value={2}>Pause</option>
                                    </Form.Select>
                                    {/* <Form.Control type="number" placeholder="No. Of Services" /> */}
                                    {/* <Form.Text className="text-muted">
                                            We'll never share your email with anyone else.
                                        </Form.Text> */}
                                </Form.Group>
                                <Row className="mb-3">
                                    <Form.Group as={Col} className="mb-3">
                                        <Form.Label>Start Date</Form.Label>
                                        <Form.Control name="start_date" type='date' onChange={handleChangeUpdateStatusData} />
                                    </Form.Group>
                                    <Form.Group as={Col} className="mb-3">
                                        <Form.Label>End Date</Form.Label>
                                        <Form.Control name="end_date" type='date' onChange={handleChangeUpdateStatusData} />
                                    </Form.Group>
                                </Row>
                                <Form.Group className="mb-3" controlId="">
                                    <Form.Label>Select Reason</Form.Label>
                                    <SingleSelectSearchCategory
                                        handleChangeInputData={handleChangeUpdateStatusData}
                                        HeaderTitle="Select Reason"
                                        SelectData={masterReasons}
                                        DynamicKey={"reason"}
                                        id={"id"}
                                        name="reason_id"
                                    ></SingleSelectSearchCategory>
                                </Form.Group>
                                {
                                    updateSubscriptionStatusData.isactivestatus == 1 ?
                                        <>
                                            <Form.Group className="mb-3" id="formGridCheckbox">
                                                <Form.Check name='refund' type="checkbox" label="Initiate Refund" onChange={handleChangeUpdateStatusData} />
                                            </Form.Group>
                                            {updateSubscriptionStatusData.refund != 0 ?
                                                <Form.Group className="mb-3" controlId="">
                                                    <Form.Label>Refund Amount</Form.Label>
                                                    <Form.Control type="number" placeholder="0" step="0.01" name='refund_amount' onChange={handleChangeUpdateStatusData} />
                                                </Form.Group>
                                                : ''
                                            }
                                        </>
                                        : ''
                                }
                                {/*  */}
                                <Button variant="primary" type="submit" disabled={updateStatusButtonLoading}>
                                    {updateStatusButtonLoading ? <ColorRing
                                        visible={true}
                                        height="30"
                                        width="30"
                                        ariaLabel="blocks-loading"
                                        wrapperStyle={{}}
                                        wrapperClass="blocks-wrapper"
                                        colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                                    /> : 'Submit'
                                    }
                                </Button>
                            </Form>
                        </>
                    }
                </Modal.Body>
            </Modal>

            <Modal size="lg" show={refundPopup} onHide={handleclose}>
                <Modal.Header closeButton>
                    <Modal.Title>Initate Refund  <br /><p style={{ fontSize: '13px' }}>Maxmimun Refund Amount: ₹{razorpayAmount}</p></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        <>
                            <Form className="mb-3" onSubmit={(e) => handleRefundForm(e, subscriptionid)}>
                                <Form.Group className="mb-3" controlId="">
                                    <Form.Label>Refund Amount
                                    </Form.Label>
                                    <Form.Control type="number" placeholder="0" step="0.01" name='refund_amount' onChange={handleChangeRefundPayload} />
                                </Form.Group>
                                {/*  */}
                                <Button variant="primary" type="submit" disabled={updateStatusButtonLoading}>
                                    {updateStatusButtonLoading ? <ColorRing
                                        visible={true}
                                        height="30"
                                        width="30"
                                        ariaLabel="blocks-loading"
                                        wrapperStyle={{}}
                                        wrapperClass="blocks-wrapper"
                                        colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                                    /> : 'Initiate Refund'
                                    }
                                </Button>
                            </Form>
                            {
                                isRefundDataLoading ?
                                    <IsLoading></IsLoading> :
                                    refundData?.items?.length > 0 ?
                                        <Table responsive>
                                            <thead>
                                                <tr>
                                                    <th>Subscription Id</th>
                                                    <th>Refund Id</th>
                                                    <th>Status</th>
                                                    <th>Payment Id</th>
                                                    <th>Amount</th>
                                                    <th>Process</th>
                                                    <th>Created At</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {refundData?.items?.map((item: any) =>
                                                    <>
                                                        <tr>
                                                            <td>{subscriptions?.id}</td>
                                                            <td>{item?.id}</td>
                                                            <td>{item?.status}</td>
                                                            <td>{item?.payment_id}</td>
                                                            <td>₹{item?.amount / 100}</td>
                                                            <td>{item?.speed_processed}</td>
                                                            <td>{new Date(item?.created_at * 1000).toLocaleString()}</td>
                                                        </tr>
                                                    </>
                                                )}
                                            </tbody>
                                        </Table>
                                        : "No Previous Refunds"
                            }
                        </>
                    }
                </Modal.Body>
            </Modal>

            {
                razorpayOrderModal ? <Modal dialogClassName="modal-large" show={razorpayOrderModal} onHide={() => setRazorpayOrderModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Razorpay Payments</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <GetRazorpaySubscriptionOrder subscription_id={subscriptionid} />

                    </Modal.Body>
                </Modal> : <></>
            }

            {
                waveOffModal &&
                <WaveOff
                    confirm='Yes'
                    cancel='No'
                    payloads={{
                        render: waveOffModal,
                        payloadData: {
                            subscriptionid: subscriptionid,
                        },
                        successTitle: 'Changes Are Saved Successfully ',
                        FailedTitle: 'Changes Cancelled ',
                    }}
                    setWaveOffModal={setWaveOffModal}
                    localKey={true}
                ></WaveOff>
            }

            {
                coinCreditPopUp && <CreditCoinsForm PopUpPostFormOpen={coinCreditPopUp} handleClose={handleclose} subscriptionId={subscriptionid} From="Create" row={{}} />
            }
            {/* {
                regnerateJobPopup && <RegnerateJob show={regnerateJobPopup} handleClose={handleclose} subscriptionId={subscriptionid} From="Create" row={{}} />

            } */}
            {
                regnerateJobPopup ? <div key={"regenerate_job"}>
                    <ToggleStatusAlertAction
                        confirm='Yes'
                        cancel='No'
                        successTitle='Do you want to Regenerate Job ?'
                        FailedTitle='Changes Cancelled '
                        payload={{
                            subscriptionid: subscriptionid,
                        }}
                        setShow={setRegnerateJobPopup}
                        show={regnerateJobPopup}
                        refreshNow={null}
                        urlEndPoint="generateAttendance"
                        setUpdatePrevState={null}
                        row={{}}
                    />
                </div> : <></>
            }

        </>
    )
}
export default DropdownMenu
