/* eslint-disable jsx-a11y/anchor-is-valid */
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import DropdownMenu from "./DropdownMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import bookedImg from "./images/Booked.png"

import { faAddressBook, faAddressCard, faBan, faBookReader, faBuilding, faCheckDouble, faCheckSquare, faHome, faLocation, faSquareCheck, faUserCheck } from "@fortawesome/free-solid-svg-icons";
import { image_Cloudfront } from "../../components/Utility";
import clsx from "clsx";
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { URL_EndPoints } from "../../Server/URL_EndPoints";
import { postRequest } from "../../Server/Requests";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import PermissionContextProvider from "../../../modules/admins/adminComponents/PermissionContextProvider";
import { adminMenuActionPermissionKeys } from "../../../modules/admins/adminConstant";
import { getSubscriptionType } from "../../Consts";
type Props = {
  className: string
  image: string
  title: any
  subscriptions: any
  description: any
  index: any
  random: any
  lengths: any
  ctAddresses: any
}
const SubscriptionsBody: React.FC<Props> = ({

  className,
  image,
  title,
  subscriptions,
  index,
  random,
  lengths,
  ctAddresses
}) => {

  const [matchedAddress, setMatchedAddress] = useState<any>({})
  const [unmatchedAddress, setUnmatchedAddress] = useState<any>({})
  const [showHideAddress, setShowHideAddress] = useState<any>(false)
  const [loader, setLoader] = useState<any>(false)
  const [currentId, setCurrentId] = useState<any>("")
  // let bookedAddress = `https://w7.pngwing.com/pngs/917/85/png-transparent-computer-icons-email-address-email-address-address-miscellaneous-text-mobile-phones-thumbnail.png`
  // let addressOnly = `${image_Cloudfront}351f2fa6-07b0-4668-9469-dac59255d3d0.png`
  // let allOtherAddress = `https://developers.google.com/static/maps/images/maps-icon.svg`

  useEffect(() => {
    const mathedAddr = ctAddresses?.find((adr: any) => adr?.id == subscriptions?.address_id)
    const unmathedAddr = ctAddresses?.filter((adr: any) => adr?.id !== subscriptions?.address_id)
    setMatchedAddress(mathedAddr)
    setUnmatchedAddress(unmathedAddr)
  }, [])

  const updateAddressHandler = async (address: any) => {
    setCurrentId(address?.id)
    setLoader(true)
    let payload = {
      subscriptionid: subscriptions?.id,
      addressid: address?.id,
    }
    const result: any = await postRequest(URL_EndPoints()?.updatesubscriptionaddress, payload, setLoader)
    if (result?.data?.status == 200) {
      toast.success(result?.data?.msg, { position: "top-center" })
    }

    else {
      toast.error(result?.data?.msg, { position: "top-center" })
    }
  }



  return (
    <div
      className={`${lengths == 1 ? "" : "card"}   bgi-no-repeat ${className}`}
      style={{
        backgroundPosition: 'right top',
        backgroundSize: '10% auto',
        backgroundImage: `url(${toAbsoluteUrl('/media/svg/shapes/' + image)})`,
      }}
    >


      {/* begin::Body */}
      <div className='card-body p-3' style={{ height: lengths == 1 ? "50vh" : "" }} >
        <div className='d-flex justify-content-between'>
          <a href='#' className='card-title fw-bold text-dark mb-3 text-hover-primary fs-4'>
            {title}
          </a>
          <span className='text-dark text-hover-primary fs-4  cursor-pointer'>
            <DropdownMenu subscriptions={subscriptions} subscriptionid={subscriptions.id} random={random}></DropdownMenu>
          </span>
        </div>
        <div className='fw-bold text-primary mt-2 my-2 w-100'>

          <span
            id={`ids-${index}-${random}`}
            className={` badge badge-primary  me-2 mb-2`}
          >
            {subscriptions?.id ? subscriptions?.id : 'No Id Available'}
            <ReactTooltip
              anchorId={`ids-${index}-${random}`}
              className="bg-primary"
              content={"Subscription id"}
            />
          </span>


          <span
            id={`cleanername-${index}-${random}`}
            className={`badge badge-light-${subscriptions?.cleanername ? 'primary' : 'danger'
              } me-2 mb-2`}
          >
            {subscriptions?.cleanername ? subscriptions?.cleanername : 'Cleaner Not Assgined'}
            <ReactTooltip
              anchorId={`cleanername-${index}-${random}`}
              className="bg-primary"
              content={"Cleaner Name"}
            />
          </span>


          <span id={`Full Cleaning Day-${index}-${random}`} className='badge badge-light-secondary me-2 text-dark mb-2'>
            {subscriptions?.fulldaycleaning ? subscriptions?.fulldaycleaning : "No Cleaning Day"}
            <ReactTooltip
              anchorId={`Full Cleaning Day-${index}-${random}`}
              className="bg-primary"
              content={"Full Cleaning Day"}
            />
          </span>


          <span id={`TimeslotName-${index}-${random}`} className='badge badge-light-secondary me-2 text-dark mb-2'>
            {

              subscriptions?.timeslotData?.name ? subscriptions?.timeslotData?.name : "No Timeslot"
            }
            <ReactTooltip
              anchorId={`TimeslotName-${index}-${random}`}
              className="bg-primary"
              content={"Timeslot Name"}
            />
          </span>

          <span id={`CustomerTimeslotName-${index}-${random}`} className='badge badge-light-secondary me-2 text-dark mb-2'>
            {
              subscriptions?.timeslotCustomerSelectedDetail ? subscriptions?.timeslotCustomerSelectedDetail?.name
                : "No CX Timeslot"
            }
            <ReactTooltip
              anchorId={`CustomerTimeslotName-${index}-${random}`}
              className="bg-primary"
              content={"Customer Timeslot Name"}
            />
          </span>


          <span className='badge badge me-2 mb-2' id={`Start - End Date-${index}-${random}`}  >
            <span className='badge badge-danger me-2 '>{subscriptions?.startdate}</span>
            <span className='badge badge-danger me-2 '> {' To '}</span>
            <span className='badge badge-danger me-2 '> {subscriptions?.enddate}</span>
            <ReactTooltip
              anchorId={`Start - End Date-${index}-${random}`}
              className="bg-primary"
              content={"Start - End Date"}
            />
          </span>


          <span className='badge badge-light-secondary me-2 text-dark mb-2' id={`Frequency-${index}-${random}`}>
            {subscriptions?.masterFrequency.name}
            <ReactTooltip
              anchorId={`Frequency-${index}-${random}`}
              className="bg-primary"
              content={"Frequency"}
            />
          </span>

          <span className='badge badge-light-secondary me-2 text-dark mb-2' id={`Payment Date-${index}-${random}`} >
            {subscriptions?.paymentdate
              ? moment(subscriptions?.paymentdate).format('YYYY-MM-DD')
              : 'Not Paid'}
            <ReactTooltip
              anchorId={`Payment Date-${index}-${random}`}
              className="bg-primary"
              content={"Payment Date"}
            />
          </span>
          <span className='badge badge-light-secondary me-2 text-dark mb-2' id="Is Active">
            {subscriptions?.status == 1 ? (subscriptions?.isactive == 0 ? "Active" : "Inactive") : (subscriptions?.status == 2 ? "Complete" : "Renewal Created")}
            <ReactTooltip
              anchorId={"Is Active"}
              className="bg-primary"
              content={"Is Active"}
            />
          </span>

          <span className='badge badge-light-secondary me-2 text-dark mb-2' id="Is Active">
            {getSubscriptionType(subscriptions?.subscription_type_id)}
            <ReactTooltip
              anchorId={"Subscription Type"}
              className="bg-primary"
              content={"Subscription Type"}
            />
          </span>

          <span
            className={`badge badge-${subscriptions?.is_autopay == 1 && subscriptions?.razorpay_token !== ''
              ? 'success'
              : 'warning'
              } me-2 text-dark mb-2`
            }
            id={`Auto Pay Status-${index}-${random}`}
          >
            <ReactTooltip
              anchorId={`Auto Pay Status-${index}-${random}`}
              className="bg-primary"
              content={"Auto Pay Status"}
            />
            {subscriptions?.is_autopay === 1 && subscriptions?.razorpay_token !== ''
              ? 'Enabled'
              : 'Disabled'}
          </span>


          <span
            className={`badge badge-light-${subscriptions?.payment_mode == "wallet"
              ? 'secondary'
              : 'secondary'
              } me-2 text-dark mb-2`}
            id={`Subscription Amount-${index}-${random}`}
          >
            {subscriptions?.payment_mode == "wallet"
              ? <>{subscriptions?.total} </>
              : <> {subscriptions?.total} </>}
            <ReactTooltip
              anchorId={`Subscription Amount-${index}-${random}`}
              className="bg-primary"
              content={"Subscription Amount"}
            />
          </span>


          <span
            className={`badge badge-light-secondary me-2 text-dark mb-2`}
            id={`Subscription Months-${index}-${random}`}
          >
            {subscriptions?.months}
            <ReactTooltip
              anchorId={`Subscription Months-${index}-${random}`}
              className="bg-primary"
              content={"Subscription Months"}
            />
          </span>


          <span
            className={`badge badge-light-secondary me-2 text-dark mb-2`}
            id={`Inactive Date-${index}-${random}`}
          >
            {subscriptions?.isactive == 2 ?
              <>
                {subscriptions?.inactive_date}
              </>
              : "No Date"
            }
            {
              subscriptions?.inactive_date &&
              <ReactTooltip
                anchorId={`Inactive Date-${index}-${random}`}
                className="bg-primary"
                content={"Inactive Date"}
              />
            }
          </span>


          <span
            className={`badge badge-light-secondary me-2 text-dark mb-2`}
            id={`Discount Price-${index}-${random}`}
          >
            {subscriptions?.discountprice}
            <ReactTooltip
              anchorId={`Discount Price-${index}-${random}`}
              className="bg-primary"
              content={"Discount Price"}
            />
          </span>


          <span
            className={`badge badge-light-secondary me-2 text-dark mb-2`}
            id={`Fine Amount-${index}-${random}`}
          >
            {subscriptions?.fine_amount}
            <ReactTooltip
              anchorId={`Fine Amount-${index}-${random}`}
              className="bg-primary"
              content={"Fine Amount"}
            />
          </span>


          <span
            className={`badge badge-light-secondary me-2 text-dark mb-2`}
            id={`ct_offer_id-${index}-${random}`}
          >
            {subscriptions?.ct_offer_id}
            <ReactTooltip
              anchorId={`ct_offer_id-${index}-${random}`}
              className="bg-primary"
              content={"City Offer Id"}
            />
          </span>


          <span
            className={`badge badge-light-secondary me-2 text-dark mb-2`}
            id={`payment_mode-${index}-${random}`}
          >
            {subscriptions?.payment_mode}
            <ReactTooltip
              anchorId={`payment_mode-${index}-${random}`}
              className="bg-primary"
              content={"Payment Mode"}
            />
          </span>

          <PermissionContextProvider keyName={adminMenuActionPermissionKeys["active-subscription-show-addresses"]}>
            <span
              className={`badge badge-light-transparent  text-dark  me-2 mb-2`}
              id={`payment_mode-${index}-${random}`}
            >
              <span className="form-check form-switch" onClick={() => setShowHideAddress((prev: any) => !prev)}>
                <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={showHideAddress} />
                <label className="form-check-label text-primary fw-bold mt-1" >ADDRESSES</label>
              </span>
            </span>
          </PermissionContextProvider>


        </div>


        {

          showHideAddress ?
            <div className=' ' >
              <div
                className={clsx('scroll-y me-n5 pe-5  max mb-4')}
                data-kt-element='messages'
                data-kt-scroll='true'
                data-kt-scroll-activate='{default: false, lg: true}'
                data-kt-scroll-max-height='auto'
                data-kt-scroll-dependencies='#kt_header, #kt_toolbar, #kt_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'
                data-kt-scroll-wrappers='#kt_content, #kt_chat_messenger_body'
              >
                <div className='px-3 pb-2 mt-4 '>
                  {
                    <table className="table table-stripped" >
                      <tbody>
                        <tr className="border" >
                          <td className="mx-2 px-2 text-dark">{subscriptions?.address ? subscriptions?.flatno + ", " + subscriptions?.address + ", " + subscriptions?.pincode : "NA"}</td>
                          <td ><FontAwesomeIcon icon={subscriptions?.addresstype == "Office" ? faBuilding : faHome} className={subscriptions?.addresstype == "Office" ? "text-warning fw-bold" : "text-success fw-bold"} /></td>
                          <td ><FontAwesomeIcon icon={faBan} style={{ cursor: "not-allowed" }} className=" fw-bold fs-4 text-danger me-4" /></td>
                        </tr>

                        <tr className="border" >
                          <td className="px-2 text-dark">{matchedAddress?.address ? matchedAddress?.flatno + ", " + matchedAddress?.address + ", " + matchedAddress?.pincode : "NA"}</td>
                          <td ><FontAwesomeIcon icon={matchedAddress?.addresstype == "Office" ? faBuilding : faHome} className={matchedAddress?.addresstype == "Office" ? "text-dark fw-bold" : "text-dark fw-bold"} /></td>
                          <td > {currentId == matchedAddress?.id && loader ? <Spinner animation="border" size="sm" /> :
                            <PermissionContextProvider keyName={adminMenuActionPermissionKeys["active-subscription-show-addresses-update-address"]}>
                              <FontAwesomeIcon icon={faEdit} className="cursor-pointer fw-bold fs-4 text-primary  me-4" onClick={() => updateAddressHandler(matchedAddress)} />
                            </PermissionContextProvider>


                          } </td>
                        </tr>

                        {unmatchedAddress?.map((element: any) => {
                          let isOffice = element?.addresstype == "Office"
                          return (
                            <tr className="border" >
                              <td className="px-2">{element?.address ? element?.flatno + ", " + element?.address + ", " + element?.pincode : "NA"}</td>
                              <td ><FontAwesomeIcon icon={isOffice ? faBuilding : faHome} className={isOffice ? "text-dark fw-bold" : "text-dark fw-bold"} /></td>
                              <td > {currentId == element?.id && loader ? <Spinner animation="border" size="sm" /> :
                                <PermissionContextProvider keyName={adminMenuActionPermissionKeys["active-subscription-show-addresses-update-address"]}>
                                  <FontAwesomeIcon icon={faEdit} className="cursor-pointer fw-bold fs-4 text-dark me-4" onClick={() => updateAddressHandler(element)} />
                                </PermissionContextProvider>
                              }</td>
                            </tr>
                          )
                        }
                        )}
                      </tbody>
                    </table>
                  }
                </div>
              </div>

            </div> : <></>
        }


      </div>
    </div>
  )
}
export { SubscriptionsBody }