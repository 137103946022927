import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate, useNavigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { MenuTestPage } from '../pages/MenuTestPage'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import { PageTitle } from '../../_metronic/layout/core'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import { MasterLayoutDrawer } from '../../MasterDrawerListCommon/MasterLayoutDrawer'
import { isAllowedUser } from '../consts/components/Utility'
import { Notification } from '../modules/Notification/NotificationTemplate'
import DashboardRoute from '../../Dashboard/components/DashboardRoute'
import RewardAndPenaltiesList from '../modules/RewardAndPenaltiesList/RewardAndPenaltiesList'
import { DashboardContainer } from '../modules/a2zTicketDashboard/DashboardContainer'
import ChampRequirementType2 from '../modules/HIRING/ChampRequirementType2/ChampRequirementType2'
import { FreezeTables } from '../modules/FreezeTable/FreezeTables'
import CompanyRequirementMap from '../modules/Maps/CompanyRequirementMap/CompanyRequirementMap'
import CustomerMissedCallList from '../modules/CustomerMissedCallLists/CustomerMissedCallList'
import CompanyCleanCustBoxMapCustom from '../modules/Maps/CompanyCleanCustBoxMap/CompanyCleanCustBoxMapCustom'
import OldDeactivateChampLists from '../modules/OldDeactivateChampLists/OldDeactivateChampLists'
import NewTicketsList from '../modules/NewTicketsList/NewTicketsList'
import ChampMissedCallList from '../modules/ChampMissedCallLists/ChampMissedCallList'
import ChampReferralBonus from '../modules/ChampReferralBonus/ChampReferralBonus'
import DashboardWrapper from '../../Dashboard/Dashboard'
import { NewDailyJobDashboard } from '../modules/newDailyJobDashboard/NewDailyJobDashboard'
import MasterDeactivationNotes from '../modules/MASTERS/MasterDeactivationNotes/MasterDeactivationNotes'
import CustomerAssignedAgentController from '../modules/customerAssignedAgents/CustomerAssignedAgentController'
import ListDetailPage from '../consts/faqs/ListDetailPage'
import ModelTrainingList from '../modules/modelTrainingList/ModelTrainingList'
// import ChampHiringAutomationList from '../modules/champHiringAutomation/ChampHiringAutomationList'
import { TicketCountList } from '../modules/ticketCountLists/TicketCountList'
import { NewPayoutTimelineList } from '../modules/payoutTimeLineList/NewPayoutTimelineList'
import NewChampAssignmentList from '../modules/newChampAssignmentList/NewChampAssignmentList'
import DeveloperTools from '../modules/developerTools/DeveloperTools'
import NewChampAvailabilityList from '../modules/newChampAssignmentList/NewChampAvailabilityList'
import { LeadsCountSourceWiseList } from '../modules/leadStatusList/LeadsCountSourceWiseList'
import { LeadsVsSourceStatusList } from '../modules/leadStatusList/LeadsVsSourceStatusList'
import { FEATURES, isFeatureAllowed } from '../consts/Permissions'
import Unauthorized from '../modules/admins/Unauthorized'
import StackBarCharts from '../modules/admins/adminComponents/adminCharts/StackBarCharts'
import AreaManagerDailyDashboard from '../modules/areaManagerDailyDashboard/AreaManagerDailyDashboard'
import { PaymentCounts } from '../modules/paymentCounts/PaymentCounts'
import CustomerJobGeneratedList from '../modules/customerJobGeneratedList/CustomerJobGeneratedList'
import UnsuccessfulSubscriptions from '../modules/unsuccessfulSubscriptions/UnsuccessfulSubscriptions'
import AgentAllotmentList from '../modules/agentAllotmentList/AgentAllotmentList'
import AgentOffersLists from '../modules/agentOffersLists/AgentOffersLists'
import { SalesCounts } from '../modules/salesCounts/SalesCounts'
import NotAtHomeLists from '../modules/notAtHomeList/NotAtHomeLists'
import { AgentDashboard } from '../modules/agentDailyDashboard/AgentDashboard'
import UserLogoutStatus from '../modules/userLogoutStatus/UserLogoutStatus'
import { AdsInsightsList } from '../modules/adsInsightsList/AdsInsightsList'
import { LongNAHList } from '../modules/longNAHLists/LongNAHList'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const ChampHiringAutomationList = lazy(() => import('../modules/champHiringAutomation/ChampHiringAutomationList'))
  const SubscriptionsPage = lazy(() => import('../modules/stats/SubscriptionsPage'))
  const SchedulesRoutes = lazy(() => import('../modules/RoutingEndPoints/SchedulesRoutes'))
  const MAPsRouting = lazy(() => import('../modules/RoutingEndPoints/MAPsRouting'))
  const AddressRouting = lazy(() => import('../modules/RoutingEndPoints/AddressRouting'))
  const CampainsRouting = lazy(() => import('../modules/RoutingEndPoints/CampainsRouting'))
  const StatsPage = lazy(() => import('../modules/statistics/StatsPage'))
  const CleanerPage = lazy(() => import('../modules/cleaner/CleanerPage'))
  const UsersPage = lazy(() => import('../modules/user-management/UsersPage'))
  const InvoicePage = lazy(() => import('../modules/invoices/InvoicePage'))
  const CallResultRoutes = lazy(() => import('../modules/RoutingEndPoints/CallResultRoutes'))
  const RenewalDashboardRoutes = lazy(() => import('../modules/RoutingEndPoints/RenewalDashboardRoutes'))
  const InventoryRoutes = lazy(() => import('../modules/RoutingEndPoints/InventoryRoutes'))
  const IframeDashboard = lazy(() => import('../modules/IframeDashboard/IframeDashboard'))
  const AppStoreRatingControllerRouter = lazy(() => import('../modules/AppStoreRating/ControllerRoute'))
  const VisitorsList = lazy(() => import('../modules/VisitorsList/VisitorsList'))
  const VisitorListChamp = lazy(() => import('../modules/VisitorListChamp/VisitorListChamp'))
  const VisitorListChampV2 = lazy(() => import('../modules/VisitorListChamp/VisitorListChampV2'))
  const VisitorsListV2 = lazy(() => import('../modules/VisitorsList/VisitorsListV2'))
  const RatingList = lazy(() => import('../modules/RatingsV2/RatingList'))
  const VehicleSubscriptionHistoryController = lazy(() => import('../modules/VehicleSubscriptionHistory/VehicleSubscriptionHistoryController'))
  const WelcomeCallListController = lazy(() => import('../modules/WelcomeCallList/WelcomeCallListController'))
  const WelcomeCall2ListController = lazy(() => import('../modules/WelcomeCall2List/WelcomeCall2ListController'))

  // new import 

  const RenewalTaskList = lazy(() => import('../modules/RenewalTaskList/RenewalTaskList'))
  const AreaWiseAvailibilityByCleaners = lazy(() => import('../modules/AreaWiseAvailibiltyByCleaners/AreaWiseAvailibilityByCleaners'))
  const DeviceLoginAllowed = lazy(() => import('../modules/DeviceLoginAllowed/DeviceLoginAllowed'))
  const VehicleTypeListController = lazy(() => import('../modules/VehicleTypeLists/VehicleTypeListController'))
  const ApprovalListMain = lazy(() => import('../modules/ApprovaLists/ApprovalListMain'))
  const AdminPanelCarselonas = lazy(() => import('../consts/adminPanelCarselona/AdminPanelCarselonas'))
  const MenuColumnToggleHander = lazy(() => import('../consts/MenuColumnToggleHander/MenuColumnToggleHander'))
  const AdminSettings = lazy(() => import('../consts/Settings/AdminSettings'))
  const DeductionsJobList = lazy(() => import('../modules/Old_JobList/DeductionsJobList'))
  const TaskListController = lazy(() => import('../modules/taskLists/TaskListController'))
  const WorkingOn2 = lazy(() => import('../consts/DateRangeCalendar/WorkingOn2'))
  const ChampDashboardHiring = lazy(() => import('../modules/HIRING/ChampDashboardHiring/ChampDashboardHiring'))
  const ChampCampaignMain = lazy(() => import('../modules/ChampCampaign/ChampCampaignMain'))
  const AeasListMains = lazy(() => import('../modules/ADDRESS/AreasList/AeasListMains'))
  const TrainingtopicsMain = lazy(() => import('../modules/MASTERS/TrainingTopicsList/TrainingtopicsMain'))
  const TrainingScheduleMainList = lazy(() => import('../modules/HIRING/TrainingSchedule/TrainingScheduleMainList'))
  const TrainingLocationMainList = lazy(() => import('../modules/HIRING/ChampsTrainingLocation/TrainingLocationMainList'))
  const LuckyDrawMainList = lazy(() => import('../modules/LuckyDraw/LuckyDrawMainList'))
  const NotificationDashboardRoutes = lazy(() => import('../modules/NotificationDashboard/NotificationDashboardRoutes'))
  const WelcomeListRoutes = lazy(() => import('../modules/WelcomeList/WelcomeListRoutes'))
  const TicketDashboardMains = lazy(() => import('../modules/TicketDashboard/TicketDashboardMains'))
  const WorkingOn = lazy(() => import('../consts/DateRangeCalendar/WorkingOn'))
  const DailyJobAssignNotAvailable = lazy(() => import('../modules/SCHEDULES _SUBSCRIPTIONS_LIST/DailyJobAssignment/DailyJobAssignment'))
  const AreaWiseAvailabilityRoute = lazy(() => import('../modules/SCHEDULES _SUBSCRIPTIONS_LIST/AreaCleanerAvailibiltybyAddress/AreaWiseAvailabilityRoute'))
  const ChampAvailabilityRoute = lazy(() => import('../modules/SCHEDULES _SUBSCRIPTIONS_LIST/ChampPermanentReplacements/ChampAvailabilityRoute'))
  const DateChangeAvailibiltyroutes = lazy(() => import('../modules/SCHEDULES _SUBSCRIPTIONS_LIST/ChangeDateAvailibillityRoutes/DateChangeAvailibiltyroutes'))
  const PausePaidSubscriptionsPaginationTwo = lazy(() => import('../modules/PausePaidSubscriptionsPaginationTwo/PausePaidSubscriptionsPaginationTwo'))
  const InactiveAutoSubscriptionsPagination = lazy(() => import('../modules/InactiveAutoSubPagination/InactiveAutoSubscriptionsPagination'))
  const InactiveSubscriptionsAgentPagination = lazy(() => import('../modules/InactiveAgentSubPaginations/InactiveAgentSubscriptionsController'))
  const PauseUnPaidSubPagination = lazy(() => import('../modules/PauseUnPaidSubPagination/PauseUnPaidSubPagination'))
  const FuturePauseListsController = lazy(() => import('../modules/FuturePauseLists/FuturePauseListsController'))
  const NewRenewalSuscriptionControllerRoute = lazy(() => import('../modules/NewRenewalSuscriptionsPagination/NewRenewalSuscriptionControllerRoute'))
  const RenewalSuscriptionControllerRoute = lazy(() => import('../modules/RenewalSuscriptionsPagination/RenewalSuscriptionControllerRoute'))
  const JobDashboardControllerRoute = lazy(() => import('../modules/JobsDashboard/JobDashboardControllerRoute'))
  const TicketListMain = lazy(() => import('../modules/TicketsList/TicketListMain'))
  const RoleModulesMains = lazy(() => import('../modules/RoleModules/RoleModulesMains'))
  const MasterServicesRoutes = lazy(() => import('../modules/RoutingEndPoints/MasterServicesRoutes'))
  const Parent = lazy(() => import('../consts/ReactTableComponents/Parent'))
  const ActiveCleanerRoute = lazy(() => import('../../app/modules/ActiveCleaner/ActiveCleanerRoute'))
  const Old_JobList = lazy(() => import('../modules/Old_JobList/Old_JobList'))
  const CleanerAbsenteeism = lazy(() => import('../modules/CleanerAbsenteeism/CleanerAbsenteeism'))
  const PlayStoreControllerRoute = lazy(() => import('../modules/PlayStoreRating/ControllerRoute'))
  const ControllerRoute = lazy(() => import('../modules/All Societies/ControllerRoute'))
  const SendNotificationTemplate = lazy(() => import('../modules/Notification/SendNotificationTemplate'))
  const ChampDeviceHashListController = lazy(() => import('../modules/ChampDeviceHashList/ChampDeviceHashListController'))
  const AreaManagerAccessListController = lazy(() => import('../modules/areaManagerAccessList/AreaManagerAccessListController'))
  const EasebuzzTransactionHistory = lazy(() => import('../modules/easebuzzTransactionHistory/EasebuzzTransactionHistory'))
  const MasterWhatsappGroups = lazy(() => import('../modules/MasterWhatsappGroups/MasterWhatsappGroups'))
  const CronHistory = lazy(() => import('../modules/cronHistory/CronHistory'))
  const LoanLists = lazy(() => import('../modules/loanHistory/LoanLists'))
  const LoanTransactionList = lazy(() => import('../modules/loanHistory/LoanTransactionList'))
  const FAQs = lazy(() => import('../consts/faqs/FAQs'))
  const ResumeList = lazy(() => import('../modules/resumeListPagination/ResumeList'))
  const PayoutTimeLineList = lazy(() => import('../modules/payoutTimeLineList/PayoutTimeLineList'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route
          // path='crafted/pages/tickets/*'
          element={
            <SuspensedView>
              <MasterLayoutDrawer />
            </SuspensedView>
          }
        />


        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardRoute />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />




        <Route
          path='hiring/*'
          element={
            <SuspensedView>
              <PageTitle>Training Locations</PageTitle>
              <TrainingLocationMainList />
            </SuspensedView>
          }
        />

        <Route
          path='hiring/training-schedules*'
          element={
            <SuspensedView>
              <PageTitle>Training Schedules</PageTitle>
              <TrainingScheduleMainList />
            </SuspensedView>
          }
        />


        <Route
          path='/hiring/training-topics*'
          element={
            <SuspensedView>
              <PageTitle>Training Topics</PageTitle>
              <TrainingtopicsMain />
            </SuspensedView>
          }
        />

        <Route
          path={`/hiring/areas`}
          element={
            <SuspensedView>
              <PageTitle>Areas List</PageTitle>
              <AeasListMains></AeasListMains>
            </SuspensedView>
          }
        />
        <Route
          path='lucky-draw/*'
          element={
            <SuspensedView>
              <PageTitle>Lucky Draw</PageTitle>
              <LuckyDrawMainList />
            </SuspensedView>
          }
        />

        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='/themes*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='admin/*'
          element={
            <SuspensedView>
              <TicketListMain />
            </SuspensedView>
          }
        />

        <Route
          path='/admin/new-tickets'
          element={
            <SuspensedView>
              <NewTicketsList />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/statistics/*'
          element={
            <SuspensedView>
              <SubscriptionsPage />
            </SuspensedView>
          }
        />
        <Route
          path='subscriptions/*'
          element={
            <SuspensedView>
              <SubscriptionsPage />
            </SuspensedView>
          }
        />
        <Route
          path='schedule/cleaner/*'
          element={
            <SuspensedView>
              <SchedulesRoutes />
            </SuspensedView>
          }
        />

        <Route
          path='apps/stats/*'
          element={
            <SuspensedView>
              <StatsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/cleaner/*'
          element={
            <SuspensedView>
              <CleanerPage />
            </SuspensedView>
          }
        />
        <Route
          path='master/*'
          element={
            <SuspensedView>
              <MasterServicesRoutes />
            </SuspensedView>
          }
        />
        <Route
          path='data/*'
          element={
            <SuspensedView>
              {/* <CommonDataListRouting /> */}
            </SuspensedView>
          }
        />
        <Route
          path='address/*'
          element={
            <SuspensedView>
              <AddressRouting />
            </SuspensedView>
          }
        />
        <Route
          path='maps/*'
          element={
            <SuspensedView>
              <MAPsRouting />
            </SuspensedView>
          }
        />
        <Route
          path='campaigns/*'
          element={
            <SuspensedView>
              <CampainsRouting />
            </SuspensedView>
          }
        />
        <Route
          path='invoice/*'
          element={
            <SuspensedView>
              <InvoicePage />
            </SuspensedView>
          }
        />
        {/* simple routing here ============================================== */}
        <Route
          path='/notification/template/*'
          element={
            <SuspensedView>
              <PageTitle>Notification Template</PageTitle>
              <Notification />
            </SuspensedView>
          }
        />
        <Route
          path='/notification/send-notification/*'
          element={
            <SuspensedView>
              <PageTitle>Send Notification</PageTitle>
              <SendNotificationTemplate />
            </SuspensedView>
          }
        />
        \{/* All Ratiggs Routes  */}
        <Route
          path='/inapp/ratings/*'
          element={
            <SuspensedView>
              <PageTitle>All Ratings</PageTitle>
              <RatingList />
            </SuspensedView>
          }
        />

        <Route
          path='/all/Societies/*'
          element={
            <SuspensedView>
              <PageTitle>All Societies</PageTitle>
              <ControllerRoute />
            </SuspensedView>
          }
        />
        <Route
          path='/ratings/PlayStore/*'
          element={
            <SuspensedView>
              <PageTitle>PlayStore</PageTitle>
              <PlayStoreControllerRoute />
            </SuspensedView>
          }
        />
        <Route
          path='/ratings/AppStore/*'
          element={
            <SuspensedView>
              <PageTitle>AppStore</PageTitle>
              <AppStoreRatingControllerRouter />
            </SuspensedView>
          }
        />
        {/* All Tickets Routes  */}
        {/* All daily/job/assign  */}
        <Route
          path='/absent/cleanerList/*'
          element={
            <SuspensedView>
              <PageTitle>Absent Cleaner List</PageTitle>
              <CleanerAbsenteeism />
            </SuspensedView>
          }
        />
        {/* All daily/job/assign  */}
        {/* All daily/job/assign  */}
        <Route
          path='/daily/job/assign/oldjob/:id/*'
          element={
            <SuspensedView>
              <PageTitle>Champ Temporary Reassign</PageTitle>
              {/* <DailyJobAssignment /> */}
              <DailyJobAssignNotAvailable></DailyJobAssignNotAvailable>
            </SuspensedView>
          }
        />
        <Route
          path='/datechange/:id/:id/:id:id/:id/:id/*'
          element={
            <SuspensedView>
              <PageTitle>Date Change</PageTitle>
              <DateChangeAvailibiltyroutes subscriptionId={24064} distenceRadius={'3'} />

            </SuspensedView>
          }
        />
        {/* All daily/job/assign  */}
        {/* old job list /job/assign  */}
        <Route
          path='/old/jobList/*'
          element={
            <SuspensedView>
              <PageTitle>Old Cleaner List</PageTitle>
              <Old_JobList />
            </SuspensedView>
          }
        />
        <Route
          path='/activecleaners'
          element={
            <SuspensedView>
              <PageTitle>Active Cleaner</PageTitle>
              <ActiveCleanerRoute />
            </SuspensedView>
          }
        />
        <Route
          path='/apps/cleaner/champ-missed-list'
          element={
            <SuspensedView>
              <PageTitle>Champ Missed Call List</PageTitle>
              <ChampMissedCallList />
            </SuspensedView>
          }
        />
        <Route
          path='/old-deactivated-champ'
          element={
            <SuspensedView>
              <PageTitle>Old Deactivated Champ Lists</PageTitle>
              <OldDeactivateChampLists />
            </SuspensedView>
          }
        />  
        <Route
          path='/champ-referral'
          element={
            <SuspensedView>
              <PageTitle>Champ Referral Bonus List</PageTitle>
              <ChampReferralBonus />
            </SuspensedView>
          }
        />
        
        {/* Champ Absent customer list permanent replacemments  */}
        <Route
          path='/champ-permanent-replacement/active_assined_champ/:id/*'
          element={
            <SuspensedView>
              <PageTitle> Champ Permanent Replacement </PageTitle>
              <NewChampAvailabilityList topHeight={true}/>
            </SuspensedView>
          }
        />
        
        <Route
          path='/champ-permanent-replacement/active_cleaner_champ/:id/*'
          element={
            <SuspensedView>
              <PageTitle> Champ Permanent Replacement </PageTitle>
              <NewChampAvailabilityList topHeight={true} /> 
            </SuspensedView>
          }
        />


        {/* Area for Sales */}
        <Route
          path='/areawisecleaner/*'
          element={
            <SuspensedView>
              <PageTitle>Area Wise Cleaner For Sales </PageTitle>
              <AreaWiseAvailabilityRoute />
            </SuspensedView>
          }
        />
        {/* Area for Sales */}
        <Route
          path='/role-module/*'
          element={
            <SuspensedView>
              <PageTitle>Roles Module List </PageTitle>
              <RoleModulesMains />
            </SuspensedView>
          }
        />
        {/* Area for Sales */}
        {/* <Route
          path='/active-customer/*'
          element={
            <SuspensedView>
              <PageTitle>Active Customers List</PageTitle>
              <JobDashboardControllerRoute />
            </SuspensedView>
          }
        /> */}
        {/* Area for Sales */}
        {/* <Route
          path='/visitors/*'
          element={
            <SuspensedView>
              <PageTitle>Visitors List </PageTitle>
              <ControllerRouteVisitors />
            </SuspensedView>
          }
        /> */}
        <Route
          path='/ticket-dashboard/*'
          element={
            <SuspensedView>
              <PageTitle>Ticket Dashboard</PageTitle>
              <TicketDashboardMains />
            </SuspensedView>
          }
        />
        <Route
          path='/dashboard-statics'
          element={
            <SuspensedView>
              <DashboardWrapper isDashboard={true} />
            </SuspensedView>
          }
        />
        <Route
          path='/new-ticket-dashboard/*'
          element={
            <SuspensedView>
              <PageTitle>New Ticket Dashboard</PageTitle>
              <DashboardContainer />
            </SuspensedView>
          }
        />
        <Route
          path='/new-daily-dashboard'
          element={
            <SuspensedView>
              <PageTitle>New Ticket Dashboard</PageTitle>
              <NewDailyJobDashboard />
            </SuspensedView>
          }
        />
        <Route
          path='/agent-daily-dashboard'
          element={
            <SuspensedView>
              <PageTitle>Agent Daily Dashboard</PageTitle>
              <AgentDashboard />
            </SuspensedView>
          }
        />

        <Route
          path='/area-manager-job-dashboard'
          element={
            <SuspensedView>
              <PageTitle>Area Manager Job Dashboard</PageTitle>
              <AreaManagerDailyDashboard />
            </SuspensedView>
          }
        />
        
        <Route
          path='/admin/job-dashboard/*'
          element={
            <SuspensedView>
              <PageTitle>All Daily Jobs Dashboard </PageTitle>
              <JobDashboardControllerRoute />
            </SuspensedView>
          }
        />
        <Route
          path='/renewal-subscriptions'
          element={
            <SuspensedView>
              <PageTitle>Renewal Subscriptions </PageTitle>
              <RenewalSuscriptionControllerRoute />
            </SuspensedView>
          }
        />
        <Route
          path='/new-renewal-subscriptions'
          element={
            <SuspensedView>
              <PageTitle>Renewal Subscriptions </PageTitle>
              <NewRenewalSuscriptionControllerRoute />
            </SuspensedView>
          }
        />
        <Route
          path='/resumed-subscriptions'
          element={
            <SuspensedView>
              <PageTitle>Resumed Subscriptions</PageTitle>
              <ResumeList />
            </SuspensedView>
          }
        />

        <Route
          path='/subscriptions/inactive-subscriptions-list/*'
          element={
            <SuspensedView>
              <PageTitle>Inactive Subscriptions List </PageTitle>
              {/* <InactiveSubscriptionsController /> */}
            </SuspensedView>
          }
        />
        <Route
          path='/inactive-subscriptions-server-side/*'
          element={
            <SuspensedView>
              <PageTitle>Inactive Subscriptions List </PageTitle>
              <Parent></Parent>
            </SuspensedView>
          }
        />
        <Route
          path='/working-on/*'
          element={
            <SuspensedView>
              <WorkingOn></WorkingOn>
            </SuspensedView>
          }
        />

        <Route
          path={`/future-pause`}
          element={
            <SuspensedView>
              <FuturePauseListsController payment_status={'paid'} status={'pause'} selectedButton={'pause_paid'} />
            </SuspensedView>

          }
        />



        <Route
          path={`/inactive-pause-paid`}
          element={
            <SuspensedView>
              {/* <PausePaidSubscriptionsController payment_status={'paid'} status={'pause'} selectedButton={'pause_paid'} /> */}
              {/* <PausePaidSubscriptionsPagination payment_status={'paid'} status={'pause'} selectedButton={'pause_paid'} /> */}
              <PausePaidSubscriptionsPaginationTwo payment_status={'paid'} status={'pause'} selectedButton={'pause_paid'} />
            </SuspensedView>

          }
        />


        <Route
          path={`/inactive-pause-unpaid`}
          element={

            <SuspensedView>
              {/* <PauseUnpaidSubscriptionsController payment_status={'unpaid'} status={'pause'} selectedButton={'pause_unpaid'} /> */}
              <PauseUnPaidSubPagination payment_status={'unpaid'} status={'pause'} selectedButton={'pause_unpaid'} />
            </SuspensedView>

          }
        />

        <Route
          path={`/inactive-auto`}
          element={

            <SuspensedView>
              {/* <InactiveAutoSubscriptionsController payment_status={''} status={'inactive'} selectedButton={'inactive_auto'} by={"automatically"} /> */}
              <InactiveAutoSubscriptionsPagination payment_status={''} status={'inactive'} selectedButton={'inactive_auto'} by={"automatically"} />
            </SuspensedView>

          }
        />

        <Route
          path={`/inactive-agent`}
          element={

            <SuspensedView>
              {/* <InactiveAgentSubscriptionsController payment_status={''} status={'inactive'} selectedButton={'inactive_agent'} by={"agent"} /> */}
              <InactiveSubscriptionsAgentPagination payment_status={''} status={'inactive'} selectedButton={'inactive_agent'} by={"agent"} />
            </SuspensedView>

          }
        />


        <Route
          path='callresults/*'
          element={
            <SuspensedView>
              <CallResultRoutes />
            </SuspensedView>
          }
        />

        <Route
          path='renewal/*'
          element={
            <SuspensedView>
              <RenewalDashboardRoutes />
            </SuspensedView>
          }
        />

        <Route
          path={`/visitors-list`}
          element={
            <SuspensedView>
              <VisitorsList />
            </SuspensedView>

          }
        />

        <Route
          path={`/champs/visitor-list`}
          element={
            <SuspensedView>
              <VisitorListChamp />
            </SuspensedView>

          }
        />

        <Route
          path={`/champs/champ-leads`}
          element={
            <SuspensedView>
              <VisitorListChampV2 />
            </SuspensedView>

          }
        />
        
        <Route
          path={`/champs/champ-leads/:id`}
          element={
            <SuspensedView>
              <VisitorListChampV2 />
            </SuspensedView>

          }
        />


        <Route
          path={`/customer-leads`}
          element={
            <SuspensedView>
              <VisitorsListV2 />
            </SuspensedView>

          }
        />
        <Route
          path={`/task-lists`}
          element={
            <SuspensedView>
              <TaskListController />
            </SuspensedView>

          }
        />

        {/* <Route
          path={`/first-time-payment-failed-list`}
          element={
            <SuspensedView>
              < FirstTimeFailedPaginationMain />
            </SuspensedView>
          }
        /> */}

        <Route
          path={`/inventory/*`}
          element={
            <SuspensedView>
              <InventoryRoutes />
            </SuspensedView>
          }
        />


        <Route
          path={`/welcome-list/*`}
          element={
            <SuspensedView>
              <WelcomeListRoutes />
            </SuspensedView>
          }
        />
        <Route
          path={`/notification-dashboard/*`}
          element={
            <SuspensedView>
              <NotificationDashboardRoutes />
            </SuspensedView>
          }
        />
        <Route
          path={`/iframe-dashboard`}
          element={
            <SuspensedView>
              <IframeDashboard />
            </SuspensedView>
          }
        />

        <Route
          path={`/champ-schemes`}
          element={
            <SuspensedView>
              <ChampCampaignMain />
            </SuspensedView>
          }
        />

        <Route
          path={`/deduction-jobs`}
          element={
            <SuspensedView>
              <DeductionsJobList />
            </SuspensedView>
          }
        />

        <Route
          path={`hiring/champ-requirement*`}
          element={
            <SuspensedView>
              {/* <ChampRequirement /> */}
              <ChampRequirementType2 />
            </SuspensedView>
          }
        />


        <Route
          path={`hiring/hiring-dashboard`}
          element={
            <SuspensedView>
              {/* <ChampRequirement /> */}
              <ChampDashboardHiring />
            </SuspensedView>
          }
        />

        <Route
          path={`hiring/hiring-dashboard-2`}
          element={
            <SuspensedView>
              {/* <ChampRequirement /> */}
              <FreezeTables />
            </SuspensedView>
          }
        />

        <Route
          path={`/WorkingOn2`}
          element={
            <SuspensedView>
              <WorkingOn2 />
            </SuspensedView>
          }
        />

        <Route
          path={`/faq/frequently-asked-question`}
          element={
            <SuspensedView>
              <FAQs />
            </SuspensedView>
          }
        />


        <Route
          path={`/faq/categories/:category/:id`}
          element={
            <SuspensedView>
              <ListDetailPage />
            </SuspensedView>
          }
        />

        <Route
          path={`/admin/settings`}
          element={
            <SuspensedView>
              <AdminSettings />
            </SuspensedView>
          }
        />
        <Route
          path={`/admin/menuColumnToggleHander`}
          element={
            <SuspensedView>
              <MenuColumnToggleHander />
            </SuspensedView>
          }
        />
        <Route
          path={`/admin/AdminPanelCarselona`}
          element={
            <SuspensedView>
              <AdminPanelCarselonas />
            </SuspensedView>
          }
        />
        <Route
          path={IsAdmin(`/admin/approval-list`)}
          element={
            <SuspensedView>
              <ApprovalListMain />
            </SuspensedView>
          }
        />
        <Route

          path={IsAdmin(`/admin/device-login-allowed`)}
          element={
            <SuspensedView>
              <DeviceLoginAllowed />
            </SuspensedView>
          }
        />
        <Route
          path={`/inactive-vehicle-list`}
          element={
            <SuspensedView>
              <VehicleTypeListController />
            </SuspensedView>
          }
        />
        <Route
          path={`/vehicle-subscription-history-list`}
          element={
            <SuspensedView>
              <VehicleSubscriptionHistoryController />
            </SuspensedView>
          }
        />
        <Route
          path={`/renewal-task-list`}
          element={
            <SuspensedView>
              <RenewalTaskList />
            </SuspensedView>
          }
        />
        <Route
          path={`/welcome-call-1-list`}
          element={
            <SuspensedView>
              <WelcomeCallListController />
            </SuspensedView>
          }
        />
        <Route
          path={`/welcome-call-2-list`}
          element={
            <SuspensedView>
              <WelcomeCall2ListController />
            </SuspensedView>
          }
        />

        <Route
          path={`/schedule/cleaner-area-availibilty`}
          element={
            <SuspensedView>
              <AreaWiseAvailibilityByCleaners />
            </SuspensedView>
          }
        />
        <Route
          path={`/schedule/no-job-subscription-list`}
          element={
            <SuspensedView>
              <CustomerJobGeneratedList />
            </SuspensedView>
          }
        />
        <Route
          path={`/champ/device-hash-list`}
          element={
            <SuspensedView>
              <ChampDeviceHashListController />
            </SuspensedView>
          }
        />
        <Route
          path={`/reward-and-penalties`}
          element={
            <SuspensedView>
              <RewardAndPenaltiesList />
            </SuspensedView>
          }
        />
        <Route
          path={`/hiring/requirement-map`}
          element={
            <SuspensedView>
              <CompanyRequirementMap />
            </SuspensedView>
          }
        />
        <Route
          path={`/customer/customer-missed-list`}
          element={
            <SuspensedView>
              <CustomerMissedCallList />
            </SuspensedView>
          }
        />

        <Route
          path={`/hiring/sub-location-map`}
          element={
            <SuspensedView>
              <CompanyCleanCustBoxMapCustom />
            </SuspensedView>
          }
        />

        <Route
          path={`/access/area-manager`}
          element={
            <SuspensedView>
              <AreaManagerAccessListController />
            </SuspensedView>
          }
        />

        <Route
          path={`/easebuzz/transaction-history`}
          element={
            <SuspensedView>
              <EasebuzzTransactionHistory />
            </SuspensedView>
          }
        />

        <Route
          path={`/master/whatsapp-groups`}
          element={
            <SuspensedView>
              <MasterWhatsappGroups />
            </SuspensedView>
          }
        />

        <Route
          path={`/customer-assigned-agents`}
          element={
            <SuspensedView>
              <CustomerAssignedAgentController />
            </SuspensedView>
          }
        />
        <Route
          path={`/cron/history`}
          element={
            <SuspensedView>
              {isFeatureAllowed(FEATURES.cronHistory) ?  <CronHistory /> : <Unauthorized />}
            </SuspensedView>
          }
        />
        <Route
          path={`/model-training/training-list`}
          element={
            <SuspensedView>
              <ModelTrainingList />
            </SuspensedView>
          }
        />
        
        <Route
          path={`/champ-campaign/list-sid`}
          element={
            <SuspensedView>
              <CronHistory />
            </SuspensedView>
          }
        />
        <Route
          path={`/loan/history`}
          element={
            <SuspensedView>
              <LoanLists />
            </SuspensedView>
          }
        />

        <Route
          path={`/loan/transaction`}
          element={
            <SuspensedView>
              <LoanTransactionList />
            </SuspensedView>
          }
        />


        <Route
          path={`/master/deactivation-notes`}
          element={
            <SuspensedView>
              <MasterDeactivationNotes />
            </SuspensedView>
          }
        />
        
        <Route
          path={`/payout/payout-timeline`}
          element={
            <SuspensedView>
              <NewPayoutTimelineList />
            </SuspensedView>
          }
        />

    

        <Route
          path={`/tracking/ticket-count-list`}
          element={
            <SuspensedView>
              <TicketCountList />
            </SuspensedView>
          }
        />

        {/* <Route
          path={`/ticket/ticket-count-source-wise`}
          element={
            <SuspensedView>
            
              <TicketSourceCountList />
            </SuspensedView>
          }
        /> */}

        

        <Route
          path={`/hiring/hiring-automation`}
          element={
            <SuspensedView>
              <ChampHiringAutomationList />
            </SuspensedView>
          }
        />
        
        <Route
          path={`/subscriptions/unsuccessful-subscriptions`}
          element={
            <SuspensedView>
              <UnsuccessfulSubscriptions />
            </SuspensedView>
          }
        />

        <Route
          path={`/champs/new-champ-assignment-list`}
          element={
            <SuspensedView>
              {/* <NewChampAssignmentList /> */}
              <NewChampAvailabilityList />

            </SuspensedView>
          }
        />
        
        <Route
          path={`/tracking/leads-count-source-wise`}
          element={
            <SuspensedView>
              <LeadsCountSourceWiseList />
            </SuspensedView>
          }
        />

        <Route
          path={`/tracking/payment-counts`}
          element={
            <SuspensedView>
              <PaymentCounts />
            </SuspensedView>
          }
        />

        <Route
          path={`/tracking/sales-counts`}
          element={
            <SuspensedView>
              <SalesCounts />
            </SuspensedView>
          }
        />

        <Route
          path={`/tracking/ads-insights`}
          element={
            <SuspensedView>
              <AdsInsightsList />
            </SuspensedView>
          }
        />

        <Route
          path={`/tracking/long-nah`}
          element={
            <SuspensedView>
              <LongNAHList />
            </SuspensedView>
          }
        />
        
        <Route
          path={`/tracking/source-vs-leads`}
          element={
            <SuspensedView>
              <LeadsVsSourceStatusList />
            </SuspensedView>
          }
        />
        {/* <Route
          path={`/user/agent-offer-allotment-list`}
          element={
            <SuspensedView>
              <AgentAllotmentList />
            </SuspensedView>
          }
        /> */}

        <Route
          path={`/user/agent-offers-list`}
          element={
            <SuspensedView>
              <AgentOffersLists />
            </SuspensedView>
          }
        />

        <Route
          path={`/not-at-home-list`}
          element={
            <SuspensedView>
              <NotAtHomeLists />
            </SuspensedView>
          }
        />
        
        <Route
          path={`/tracking/user-call-status`}
          element={
            <SuspensedView>
              <StackBarCharts />
            </SuspensedView>
          }
        />
        <Route
          path={`/tracking/user-logout-status`}
          element={
            <SuspensedView>
              <UserLogoutStatus />
            </SuspensedView>
          }
        />
        
        <Route
          path={`/developer/tools`}
          element={
            <SuspensedView>
              <DeveloperTools />
            </SuspensedView>
          }
        />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const IsAdmin = (url: any) => {

  if (isAllowedUser?.includes(Number(localStorage.getItem("user")))) {
    return url
  }
  else {
    // window.location.href = '/dashboard';
    return "dashboard/*"

  }
}
const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}
export { PrivateRoutes }




